import React from "react";
import { WindowSelect, BuildingSelect, ObjectSelect,AtmosphereInput,PixelBoardInput } from "./Controls";

import {  Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const ControlMainStyle = styled.div`

    .title{
        color:#ffffff;
        font-weight: 600;
        .right_arrow{
            font-size: 12px;
            position: relative;
            top:-2px;
            /* padding-left:0.5em; */
        }
    }
    .card{
        margin-bottom: 10px;
        position: relative;
        .image{
            max-width: 260px;
        }
        @media (max-width:575px){
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top:1em;
            margin-bottom: 1em;
            .title{
                width: 100%;
            }
            .image{
                width: 25%;
                float: left;
            }
            .control{
                width: 70%;
                //float: left;
                margin-left: 5%;
                height: 50px;
            }
            button{
                right: 0px;
                top: -40px;
            }
        }
        small{
            text-align: right;
            color:#ffffff;
            display: block;
            padding-top: 5px;
        }
        .control{
        color:#ffffff;
        border:1px solid #ffffff;
        padding:10px;
        display: flex;
        align-items: center;
        position: relative;
        select{
            width: 100%;
            background-color: #1A1613;
            option:hover,
            option:focus,
            option:active,
            option:checked {
            color:#000000;
            /* background: linear-gradient(#5A2569, #5A2569); */
            }
        }
            input{
                background: transparent;
                padding-left: 10px;
                &:focus{
                    border:0px;
                    outline: 0px;
                }
            }
        }
    }
    .wrap{
            position: relative;
            width: 100%;
            @media (max-width:575px){
                display: flex;
                align-items: center;
            }
           
            .close{
                cursor: pointer;
                position: absolute;
                right: 0;
                border: 1px solid #fff;
                color: #ffffff;
                background-color: #000000;
                padding: 5px 12.5px;
                @media (max-width:575px){
                    top: -25px;
                }
                &:hover{
                    background-color: #ffffff;
                    color:#000000;
                }
                
            }
            .mask{
                background: rgba(0,0,0,0.8);
                height: 100%;
                position: absolute;
                top: 0px;
                width: 100%;
                span{
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    border: 1px solid #fff;
                    color: #ffffff;
                    background-color: #000000;
                    padding: 5px 11px;
                    &:hover{
                    background-color: #ffffff;
                    color:#000000;
                    }
                }
            }
        }



`;

const ControlMain = () => {
    

    return(
        <ControlMainStyle>
                    <Row className="control_main"  justifyContent="between" >
                        <Col smAuto >
                            <WindowSelect />
                        </Col>
                        <Col smAuto>
                            <BuildingSelect />
                        </Col>
                        <Col smAuto>
                            <ObjectSelect />
                        </Col>
                        <Col smAuto>
                            <AtmosphereInput />
                        </Col>
                        <Col smAuto>
                            <PixelBoardInput />
                        </Col>
                        
                    </Row>
        </ControlMainStyle>



    );
}

export default ControlMain;