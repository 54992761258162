import React from "react";

import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const Stylefeatures = styled.section`
padding:4em 2em;
background-color: #ffffff;
margin-top: -3px;
margin-bottom: -1px;
.feat_title{
    text-align: center;
    margin-bottom: 2.5em;
}
.feat_wrap{
    justify-content: center;
    align-items: flex-start;
}
.item{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding:0 1.5em;
    img{
        width: 100%;
        max-width: 6em;
    }
    h3{
        font-size: 2rem;
        color:#707070;
        margin:0.5em 0 0.2em;
    }
    p{
        color:#707070;
        text-align: center;
    }
}
@media (max-width:991px) {
    padding:1em 1em 4em;
    .feat_title{
        text-align: left;
    }
    .item{
        margin: 1em 0;
        img{
            //max-width:6em;
        }
    }    
}
`;


const Keyfeatures = () =>{
    return(
        <Stylefeatures>
            <Container>
            <div className="feat_title">
                <h2>Key Features</h2>
            </div>
                <Row className="feat_wrap">
                <Col col={12} lg={3}>
                    <div className="item">
                        <img src={process.env.PUBLIC_URL + '/images/cursor.png'} alt="" />
                        <h3>Interactive</h3>
                        <p>Holders can interact with the NFT. They don’t just “view” from one side of a window, but can change scenery “outside the window” at their own will.</p>
                    </div>
                </Col>
                <Col col={12} lg={3}>
                    <div className="item">
                        <img src={process.env.PUBLIC_URL + '/images/modularization.png'} alt="" />
                        <h3>Modularization</h3>
                        <p>With the exclusive “MERGE” mechanism, we invite collectors to co-create 1:1 train-NFTs.</p>
                    </div>
                </Col>
                <Col col={12} lg={3}>
                    <div className="item">
                        <img src={process.env.PUBLIC_URL + '/images/visual.png'} alt="" />
                        <h3>Audio-Visual</h3>
                        <p>As one merges visual NFTs, the music will be randomly pieced up and a new audio-visual NFT will be made based on NFT parameters.</p>
                    </div>
                </Col>
                <Col col={12} lg={3}>
                    <div className="item">
                        <img src={process.env.PUBLIC_URL + '/images/community.png'} alt="" />
                        <h3>Community</h3>
                        <p>It attempts to build an interactive-art community rooted in the Tezos ecosystem.</p>
                    </div>
                </Col>
                </Row>
            </Container>
        </Stylefeatures>
    );
}
export default Keyfeatures