import React from "react";
import {  BrowserRouter as Router } from "react-router-dom";
import MyContextProvider from './context/MyContext';
import Header from './components/Header';
import Footer from './components/Footer';
import AnimatedRoutes from "./components/AnimatedRoutes";


function App() {
  return (
    <div className="App">
      <Router>
        <MyContextProvider>
          <Header />
          <AnimatedRoutes />
          <Footer />
        </MyContextProvider>
      </Router>

    </div>
  );
}

export default App;
