import React from "react";
import { Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Parser from 'html-react-parser';
import { Link } from "react-router-dom";

import sideData from './data.json'

const Styleaccordion = styled.section`
background-image: 
url('images/cat3.png'),
url('images/cat1.png');
background-repeat:
no-repeat,
no-repeat;
background-size: 
13% 40%,
13% 19%;
background-position: 
bottom -8% right 3%,
bottom -6% right 15%;
background-color: #A2C4CD;
padding:4em 2em;
text-align: center;
position: relative;
@media (max-width:768px) {
    background-size: 
    30% 25%,
    30% 10%;
    background-position: 
    bottom -5% right 30%,
    bottom -2.5% right 70%;
    padding:4em 1em 15em;    
}

#faq{
    position: absolute;
    top: -100px;
}
.faq_title{
    //margin-bottom: 2em;
    //text-align: center;
}

.accordion-list{
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #A2C4CD;
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
}

.accordion-item{
    text-align: left;
    border-bottom: 2px solid #707070;
    &-open{    
        .accordion-item__icon{
            transform: rotate(180deg);
        }
        
        .accordion-item__inner{
            max-height: 100rem;
            transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
            transition-duration: 0.5s;
            transition-property: max-height;
        }
        
        .accordion-item__content{
            opacity: 1;
            background-color: #A2C4CD;
            transform: translateY(0);
            transition-delay: 0.2s;
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
            transition-property: opacity, transform;
        }
    }
    &__line{
        display: block;
        padding: 2em 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
        position: relative;
    }
    
    &__title{
        font-size: 1.375rem;
        //margin: 0;
        margin-right:0.5em;
        font-weight: 600;
        color: #000;
    }
    
    &__icon{
        width: 34px;
        height: 18px;
        min-width: 36px;
        transition: transform 0.3s ease-in-out;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('images/arrow-icon.svg');
    }
    
    &__inner{
        max-height: 0;
        overflow: hidden;
        //text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
        transition-duration: 0.5s;
        transition-property: max-height;
        z-index: 1;
        position: relative;
    }
    
    &__content{
        opacity: 0;
        transform: translateY(-1rem);
        transition-timing-function: linear, ease;
        transition-duration: 0.1s;
        transition-property: opacity, transform;
        transition-delay: 0.5s;
        margin-bottom: 2em;
        margin-right:2em;
        //padding: 0 1.2rem 1.2rem;
    }
    
    &__paragraph{
        margin: 0;
        text-align: left;
        color: #000;
        p{
            line-height: 1.7em;
            font-weight: 500;
        }
    }
}

.button_wrap{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
    a{
        border: 2px solid #000000;
        color: #000000;
        border-radius: 25px;
        padding: 10px 0;
        min-width: 160px;
        display: inline-block;
        text-align: center;
        -webkit-transition: 0.5s all;
        transition: 0.5s all;
        &:hover{
            background-color: #000000;
            color:#fff;
            -webkit-transition: 0.5s all;
            transition: 0.5s all;
        }
    }
}

@media (max-width:768px) {
    .accordion-item{
        &__line{
            padding:1em 0;
        }

        &__title{
            font-size: 1.125rem;
        }
        
        &__icon{
            width: 11px;
            height: 21px;
            min-width: 22px;
        }
        &__content{
            margin-bottom: 2em;
            margin-right:1em;
        }
    }
}

`;


const data = sideData;

class Accordion extends React.Component {
    render () {
        return (
        <Styleaccordion >
            <span id="faq"></span>
            <Container>
                <div className="faq_title">
                    <h2>FAQ</h2>
                </div>
                <ul {...{ className: 'accordion-list' }}>
                    {data.map((data, key) => {
                        return (
                        <li {...{ className: 'accordion-list__item', key }}>
                            <AccordionItem {...data} />
                        </li>
                    )
                    })}
                </ul>
                <div className="button_wrap">
                    <Link to="/how_to_merge">How to Merge &#62;</Link>
                </div>
            </Container>
        </Styleaccordion>
        )
    }
}

class AccordionItem extends React.Component {
    state = {
        opened: false
}

render () {
    const {
        props: {
            paragraph,
            title
        },
        state: {
            opened
        }
    } = this

    return (
    <div
    {...{
        className: `accordion-item ${opened && 'accordion-item-open'}`,
        onClick: () => { this.setState({ opened: !opened }) }
    }}
    >
        <div {...{ className: 'accordion-item__line' }}>
            <h3 {...{ className: 'accordion-item__title'}}>{title}</h3>
            <span {...{ className: 'accordion-item__icon' }}/>
        </div>
        <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
                <div {...{ className: 'accordion-item__paragraph' }}>
                    
                    <p>{Parser(paragraph.replace(/(<? *script)/gi, 'illegalscript'))}</p>
                </div>
            </div>
        </div>
    </div>
    )
    }
}
export default Accordion;