import React,{  useState, useContext, useEffect } from "react";
import styled from 'styled-components';
import { gql,useQuery } from "@apollo/client";
import axios from 'axios';
import { MyContext } from "../../context/MyContext";

const Button = styled.button`

    background-color: #ffffff;
    text-align: center;
    padding: 0px 0;
    width: 100%;
    border-radius: 25px;
    margin-bottom: 10px;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
    span{
        font-size: 30px;
        padding-bottom: 5px;
        display: inline-block;
    }
    &:hover{
        opacity: 0.8;
        -webkit-transition: 0.5s all;
        transition: 0.5s all;
    }
`;
const RemoveButton = styled.button`
    border-radius: 25px;
    border: 1px solid #000000;
    position: absolute;
    right: 10px;
    top: 7px;
    height: 30px;
    width: 30px;
    background-color: #ffffff;
    color: #000000;
`;



export const WindowSelect = () => {

    const [ image ,setImage] = useState(process.env.PUBLIC_URL + '/images/cover1.jpg');
   
    const [selectedOption, setSelectedOption] = useState('default');
    const context = useContext(MyContext)
    const wallet = context.address

    const TOKEN_QUERY = gql`
            query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt"){
                token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
            }, limit: $limit, offset: $offset) {
                    name
                    token_id
                    display_uri
                    thumbnail_uri
                }
            }
        `;
    const variables = {
            "GenTokenNameRegex": "^BlockTrain Window #[0-9]+$",
            "Holder": wallet,
            "limit": 500,
            "offset": 0
        };
            
    
    const { data, loading, error } = useQuery(TOKEN_QUERY,{
            variables: variables,
            // skip: wallet
    });

        if (loading) {
            return <div>
                            <p>loading....</p>
                </div>  
        }
        if (error) {
            
            return <div className="card">
                <h3 className="title">Window<span className="right_arrow">{" >"}</span></h3>
                <div className="image">
                    <img src={image} alt="" />
                </div>
                <div className="control">
                    <label htmlFor="window_select">#</label>
                    <select>
                                <option value="none">
                                    none
                                </option>
                    </select>
                    
                </div>
            </div>
        }
        
        const handleChange = (event) => {
            // console.log(data.token);
            if(event.target.value ===''){
                setImage(process.env.PUBLIC_URL + '/images/cover1.jpg')
            }
             data.token.forEach(function(item) {
                
                if(event.target.value === item.token_id){
                    const changeImage = item.display_uri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")
                    setImage(changeImage)
                }
            
            });
            setSelectedOption(event.target.value)
           
        }
      
    return (
        <div className="card">
            <h3 className="title">Window<span className="right_arrow">{" >"}</span></h3>
            <div className="image">
                <img src={image} alt="" />
            </div>
            <div className="control">
                <label htmlFor="window_select">#</label>
                <select id="window_select"
                    value={selectedOption}
                    onChange={handleChange}
                >
                            <option value="">
                                Select
                            </option>
                        {data.token.map((nftItem) => (
                            <option value={nftItem.token_id} key={nftItem.token_id}>
                                {nftItem.name}
                            </option>
                         
                        ))}
                </select>
                
            </div>
        </div>
      );
}

export const BuildingSelect = () => {

    const [ image ,setImage] = useState(process.env.PUBLIC_URL + '/images/cover2.jpg');
   
    const [selectedOption, setSelectedOption] = useState('default');
    const context = useContext(MyContext)
    const wallet = context.address
    const TOKEN_QUERY = gql`
            query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt"){
                token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
            }, limit: $limit, offset: $offset) {
                    name
                    token_id
                    display_uri
                    thumbnail_uri
                }
            }
        `;
    const variables = {
            "GenTokenNameRegex": "^BlockTrain Building #[0-9]+$",
            "Holder": wallet,
            "limit": 500,
            "offset": 0
        };
            
    
    const { data, loading, error } = useQuery(TOKEN_QUERY,{
            variables: variables,
            // skip: wallet
    });

        if (loading) return (
            <div>
                        <p>loading....</p>
            </div>
        );
        if (error) return (
            <div className="card">
                <h3 className="title">Building<span className="right_arrow">{" >"}</span></h3>
                <div className="image">
                    <img src={image} alt="" />
                </div>
                <div className="control">
                    <label htmlFor="window_select">#</label>
                    <select>
                                <option value="none">
                                    none
                                </option>
                    </select>
                    
                </div>
            </div>
        );

        const handleChange = (event) => {
            // console.log(data.token);
            if(event.target.value ===''){
                setImage(process.env.PUBLIC_URL + '/images/cover2.jpg')
            }
             data.token.forEach(function(item) {
                
                if(event.target.value === item.token_id){
                    const changeImage = item.display_uri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")
                    setImage(changeImage)
                }
            
            });
            setSelectedOption(event.target.value)
           
        }
   
    return (
        <div className="card">
            <h3 className="title">Building<span className="right_arrow">{" >"}</span></h3>
            <div className="image">
                <img src={image} alt="" />
            </div>
            <div className="control">
                <label htmlFor="building_select">#</label>
                <select id="building_select"
                    value={selectedOption}
                    onChange={handleChange}
                >
                            <option value="">
                                Select
                            </option>
                        {data.token.map((nftItem) => (
                            <option value={nftItem.token_id} key={nftItem.token_id}>
                                {nftItem.name}
                            </option>
                         
                        ))}
                </select>
                
            </div>
        </div>
      );
}

export const ObjectSelect = () => {

    const context = useContext(MyContext)
    const wallet = context.address

    const initialState = [
        {image: process.env.PUBLIC_URL + '/images/cover3.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover3.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover3.jpg'}
        
    ];
    const initialValue = [
        {value: ''},
        {value: ''},
        {value: ''}
        
    ];
    const [selectedOption, setSelectedOption] = useState(initialValue);
    const [image,setImage] = useState(initialState);
    const [Rows, setRows] = useState(0);
    const [state, setState] = useState(true);

    const addCard = () => {
        
        setRows(Rows+1);
    };
    const removeCard = (event) => {
        setRows(Rows-1);
        let TargetId = event.target.id.replace('Pixel_Board_remove','');
        //  console.log(TargetId);
          setSelectedOption({
              ...selectedOption,
              [TargetId]: {value:''}
          })
          setImage({
              ...image,
              [TargetId]: {image: process.env.PUBLIC_URL + '/images/cover3.jpg'},
          })
    };

    const closeControl = () => {
        setState(true)
        setSelectedOption({
            ...selectedOption,
            [0]: {value: ''},
            [1]: {value: ''},
            [2]: {value: ''},
        })
        setImage({
            ...image,
            [0]: {image: process.env.PUBLIC_URL + '/images/cover3.jpg'},
            [1]: {image: process.env.PUBLIC_URL + '/images/cover3.jpg'},
            [2]: {image: process.env.PUBLIC_URL + '/images/cover3.jpg'},
        })
        setRows(0);
    }
    const openControl = () => {
        setState(false) 
    }


      const TOKEN_QUERY = gql`
              query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt"){
                  token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
              }, limit: $limit, offset: $offset) {
                      name
                      token_id
                      display_uri
                      thumbnail_uri
                  }
              }
          `;
      const variables = {
              "GenTokenNameRegex": "^BlockTrain Object #[0-9]+$",
              "Holder": wallet,
              "limit": 500,
              "offset": 0
          };
              
      
      const { data, loading, error } = useQuery(TOKEN_QUERY,{
              variables: variables,
              // skip: wallet
      });
  
          if (loading) return (
              <div>
                          <p>loading....</p>
              </div>
          );
          if (error) return (
              <div className="card">
                  <h3 className="title"><a href="https://www.fxhash.xyz/generative/20538" rel="noreferrer" target="_blank">Object<span className="right_arrow">{" >"}</span></a></h3>
                  <div className="image">
                      <img src={image[0].image} alt="" />
                  </div>
                  <div className="control">
                      <label htmlFor="Object">#</label>
                      <select>
                                  <option value="none">
                                      none
                                  </option>
                      </select>
                      
                  </div>
              </div>
          );
        const handleChange = (event) => {
            
            let TargetId = event.target.id.replace('objectId','');
            setSelectedOption({
                ...selectedOption,
                [TargetId]: {value: event.target.value}
            })
            console.log(TargetId);


            if(event.target.value ===''){
                setImage({
                    ...image,
                    [TargetId]: {image: process.env.PUBLIC_URL + '/images/cover3.jpg'}
                })
            }
            data.token.forEach(function(item) {
                
                if(event.target.value === item.token_id){
                    const changeImage = item.display_uri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")
                    setImage({
                        ...image,
                        [TargetId]: {image: changeImage}
                    })
                }
            
            });
            
           
        }
    return (
        <div>
            <div className="card">
                    
                    <h3 className="title">Object<span className="right_arrow">{" >"}</span></h3>
                <div className="wrap">
                    {!state && <span onClick={closeControl} className="close">X</span>}
                    <div  className="image">
                        <img src={image[0].image} alt="" />
                    </div>
                    <div className="control">
                        <label htmlFor="objectId0">#</label>
                        <select className="obj_select" id="objectId0"
                            value={selectedOption[0].value}
                            onChange={handleChange}
                        >
                                    <option value="">
                                        Select
                                    </option>
                                {data.token.map((nftItem) => (
                                    <option value={nftItem.token_id} key={nftItem.token_id}>
                                        {nftItem.name}
                                    </option>
                                
                                ))}
                        </select>
                    </div>
                    {state &&<div className="mask">
                            <span onClick={openControl}>✔</span>
                        </div>}
                </div>
                    {/* <small>Minting opens on fxhash Oct 28</small> */}
            </div>

            {!state &&Array.from({ length: Rows }, (_, index) => (
                <div className="card" key={index}>
                    
                    <div className="image">
                        <img src={image[index+1].image} alt="" />
                        {Rows === index+1 && <RemoveButton id={"Pixel_Board_remove"+ parseInt(index+1)} onClick={removeCard}>X</RemoveButton>}
                    </div>
                    <div className="control">
                        <label htmlFor={"objectId" + parseInt(index+1)}>#</label>
                        <select className="obj_select" id={"objectId" + parseInt(index+1)}
                            value={selectedOption[parseInt(index+1)].value}
                            onChange={handleChange}
                        >
                                    <option value="">
                                        Select
                                    </option>
                                {data.token.map((nftItem) => (
                                    <option value={nftItem.token_id} key={nftItem.token_id}>
                                        {nftItem.name}
                                    </option>
                                
                                ))}
                        </select>
                      
                    </div>
                  
                </div>
            ))}
            {!state &&  <div>
                {Rows<2 && <Button  onClick={addCard}><span>+</span></Button>}
            </div>}
        </div>
       
      );
}

export const AtmosphereInput = () => {
    // const [ inputVal, setInputVal] = useState('');
    const [ image, setImage] = useState(process.env.PUBLIC_URL + '/images/cover4.jpg');
    const [selectedOption, setSelectedOption] = useState('default');
    const [state, setState] = useState(true);
    const context = useContext(MyContext)
    const wallet = context.address
    const TOKEN_QUERY = gql`
            query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt"){
                token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
            }, limit: $limit, offset: $offset) {
                    name
                    token_id
                    display_uri
                    thumbnail_uri
                }
            }
        `;
    const variables = {
            "GenTokenNameRegex": "^BlockTrain Atmosphere #[0-9]+$",
            "Holder": wallet,
            "limit": 500,
            "offset": 0
        };
            
    
    const { data, loading, error } = useQuery(TOKEN_QUERY,{
            variables: variables,
            // skip: wallet
    });

        if (loading) return (
            <div>
                        <p>loading....</p>
            </div>
        );
        if (error) return (
            <div className="card">
                <h3 className="title">Atmosphere<span className="right_arrow">{" >"}</span></h3>
                <div className="image">
                    <img src={image} alt="" />
                </div>
                <div className="control">
                    <label htmlFor="Atmosphere_select">#</label>
                    <select>
                                <option value="none">
                                    none
                                </option>
                    </select>
                    
                </div>
            </div>
        );
        const handleChange = (event) => {
            // console.log(data.token);
            if(event.target.value ===''){
                setImage(process.env.PUBLIC_URL + '/images/cover4.jpg')
            }
             data.token.forEach(function(item) {
                
                if(event.target.value === item.token_id){
                    const changeImage = item.display_uri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")
                    setImage(changeImage)
                }
            
            });
            setSelectedOption(event.target.value)
           
        }
        const closeControl = () => {
            setState(true)
            setSelectedOption('')
            
           
        }
        const openControl = () => {
            setState(false) 
        }
    return (
        <div className="card">
            <h3 className="title">Atmosphere<span className="right_arrow">{" >"}</span></h3>
            <div className="wrap">
            {!state && <span onClick={closeControl} className="close">X</span>}
                <div className="image">
                    <img src={image} alt="" />
                </div>
                <div className="control">
                    <label htmlFor="Atmosphere_select">#</label>
                    <select id="Atmosphere_select"
                        value={selectedOption}
                        onChange={handleChange}
                    >
                                <option value="">
                                    Select
                                </option>
                            {data.token.map((nftItem) => (
                                <option value={nftItem.token_id} key={nftItem.token_id}>
                                    {nftItem.name}
                                </option>
                            
                            ))}
                    </select>
                </div>
                {state &&<div className="mask">
                            <span onClick={openControl}>✔</span>
                        </div>}
            </div>
            {/* <small>Minting opens on fxhash Oct 28</small> */}
        </div>
      );
}
export const PixelBoardInput = () => {
    var ls = require('local-storage')
    const auth = ls.get('auth')
    const PixelBoardApi = "https://api.akaswap.com/v2/curations/BlockTrainPixelBoard"
    const [Rows, setRows] = useState(0);
    const [blockTrainPixelBoardList,setBlockTrainPixelBoardList] = useState([]);
    const [state, setState] = useState(true);
    const context = useContext(MyContext)
    
    const initialValue = [
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''}
        
    ];
    const initialState = [
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        
    ];
    const [selectedOption, setSelectedOption] = useState(initialValue);
    const [image ,setImage] = useState(initialState);

    const handleChange = (event) => {
            
        let TargetId = event.target.id.replace('pxiel_board_Id','');
        setSelectedOption({
            ...selectedOption,
            [TargetId]: {value:event.target.value}
        })
        // console.log(event.target.value);
        let imageChange = ''
        blockTrainPixelBoardList.forEach(function(item, i) {
            if(event.target.value === item.tokenId){
                imageChange = item.image
              
            }
        });

        setImage({
            ...image,
            [TargetId]: { image: imageChange}
        })

        if(event.target.value ===''){
            setImage({
                ...image,
                [TargetId]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'}
            })
        }
    }

    const addCard = () => {
        setRows(Rows+1);
    };
    const removeCard = (event) => {
        setRows(Rows-1);
        let TargetId = event.target.id.replace('Pixel_Board_remove','');
        //  console.log(TargetId);
          setSelectedOption({
              ...selectedOption,
              [TargetId]: {value:''}
          })
          setImage({
              ...image,
              [TargetId]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
          })
    };
    const closeControl = () => {
        setState(true)
        setSelectedOption({
            ...selectedOption,
            [0]: {value: ''},
            [1]: {value: ''},
            [2]: {value: ''},
            [3]: {value: ''},
            [4]: {value: ''},
            [5]: {value: ''},
            [6]: {value: ''},
            [7]: {value: ''},
            [8]: {value: ''},
        })
        setImage({
            ...image,
            [0]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [1]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [2]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [3]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [4]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [5]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [6]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [7]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [8]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        })
        setRows(0);
    }
    const openControl = () => {
        setState(false) 
    }
    useEffect(() => {
        const PixelBoardTokenIdData = []
        const PixelBoardTokenListData = []
        axios.get(PixelBoardApi)
        .then((response) => {
            const data = response.data[0]
            

            data.forEach((item, i) =>{
                PixelBoardTokenIdData.push(item.tokenId) 
            });
           const tokenIdToString = PixelBoardTokenIdData.join()
           const getAccountToken = "https://api.tzkt.io/v1/tokens/balances?account="+auth+"&token.contract=KT1AFq5XorPduoYyWxs5gEyrFK6fVjJVbtCj&token.tokenId.in="+tokenIdToString+"&offset=0&limit=10000&balance.ne=0"

            axios.get(getAccountToken)
            .then((response) => {
                    const AccountTokenData = response.data
                    // console.log(AccountTokenData);
                    AccountTokenData.forEach((item, i) =>{
                        PixelBoardTokenListData.push({
                             "tokenId": item.token.tokenId,
                             "name": item.token.metadata.name.replace("BlockTrain-PixelBoard", "").replace("-", "").replace("：", ""),
                             "image": item.token.metadata.displayUri.replace("ipfs://", "https://assets.akaswap.com/ipfs/"),
                         }) 
                    });
                    setBlockTrainPixelBoardList(PixelBoardTokenListData)
                });
            });
            
      },[PixelBoardApi,auth])
      
    //   console.log(blockTrainPixelBoardList);
    return (
        <div>
            <div className="card">
                    
                    <h3 className="title">
                        <a href="https://akaswap.com/curation/BlockTrainPixelBoard" rel="noreferrer" target="_blank">
                            PixelBoard<span className="right_arrow">{" >"}</span>
                        </a>
                    </h3>
                    <div className="wrap">
                    {!state && <span onClick={closeControl} className="close">X</span>}
                    <div className="image">
                        <img src={image[0].image} alt="" />
                    </div>
                    <div className="control">
                        <label htmlFor={"pxiel_board_Id0"}>#</label>
                        <select className="pxiel_board_select" id={"pxiel_board_Id0"}
                            value={selectedOption[0].value}
                            onChange={handleChange}
                        >
                                    <option value="">
                                        Select
                                    </option>
                                { context.acc?.address && blockTrainPixelBoardList.map((nftItem) => (
                                    <option value={nftItem.tokenId} key={nftItem.tokenId}>
                                        {nftItem.name}
                                    </option>
                                
                                ))}
                        </select>
                    </div>
                    {state &&<div className="mask">
                            <span onClick={openControl}>✔</span>
                        </div>}
                    </div>
            </div>

            {!state && Array.from({ length: Rows }, (_, index) => (
                <div className="card" key={index}>
                   
                    <div className="image">
                        <img src={image[index+1].image} alt="" />
                        {Rows === index+1 && <RemoveButton id={"Pixel_Board_remove"+ parseInt(index+1)} onClick={removeCard}>X</RemoveButton>}
                    </div>
                    <div className="control">
                        <label htmlFor={"pxiel_board_Id" + parseInt(index+1)}>#</label>
                        <select className="pxiel_board_select" id={"pxiel_board_Id" + parseInt(index+1)}
                            value={selectedOption[parseInt(index+1)].value}
                            onChange={handleChange}
                        >
                                    <option value="">
                                        Select
                                    </option>
                                {context.acc?.address && blockTrainPixelBoardList.map((nftItem) => (
                                    <option value={nftItem.tokenId} key={nftItem.tokenId}>
                                        {nftItem.name}
                                    </option>
                                
                                ))}
                        </select>
                    </div>
                </div>
            ))}
             {!state &&  <div>
                {Rows<8 && <Button  onClick={addCard}><span>+</span></Button>}
            </div>}
            {/* {context.acc?.address &&<div>
               
            </div>} */}
           
            
        </div>
       
      );
}


