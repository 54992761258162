import React from "react";
import Keyvision from '../../components/Home/Keyvision';
import Simulator  from '../../components/Home/Simulator';
import About from "../../components/Home/About";
import Keyfeatures from "../../components/Home/Keyfeatures";
import Triangle from "../../components/Home/Triangle";
import HomeSlider from '../../components/Home/SliderBlock';
import Accordion from '../../components/Home/Accordion';
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const Home = () => {
    return(
        <>
        <Helmet>‍
        <title>BlockTrain - Home</title>‍
        <meta name="description" content="Traveling between Dream and Reality" />        
        <meta name="twitter:card" content="" />        
        <meta name="twitter:site" content="BlockTrain" />        
        <meta name="twitter:creator" content="BlockTrain" />        
        <meta name="twitter:title" content="BlockTrain - Home" />        
        <meta name="twitter:description" content="Traveling between Dream and Reality" />        
        <meta name="twitter:image" content="https://www.blocktrain.art/images/share.png"/>        
        <meta property="og:title"  content="BlockTrain - Home" />       
        <meta property="og:description"  content="Traveling between Dream and Realityt" />      
        <meta property="og:image" content="https://www.blocktrain.art/images/share.png"/>    
        <meta property="og:url" content="https://www.blocktrain.art" />
        <meta property="og:site_name" content="BlockTrain - Home" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type"  content="website" />
        </Helmet>
        <motion.div 
            id="page_content"
            className="page_content"
            initial={{opacity:0}}
            animate={{opacity:1}}
            exit={{opacity:0}}
        >
            <Keyvision />
            <Simulator />
            <About />
            <Keyfeatures />
            <Triangle />
            <HomeSlider />
            <Accordion />
        </motion.div>
        </>
    )
};
export default Home;
