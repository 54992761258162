import React from 'react';

import styled from 'styled-components';

const Stylelink = styled.a`
border: 2px solid #ffffff;
color: #ffffff;
border-radius: 25px;
padding: 10px 0;
margin-left: 15px;
margin-right: 15px;
min-width: 150px;
display: inline-block;
text-align: center;
-webkit-transition: 0.5s all;
transition: 0.5s all;
&:hover{
    opacity:.8;
}
`;

export const LinkButton = (props)=>{
    return (
        <Stylelink className="btn_view" href={props.link} target={props.open}>{props.name}</Stylelink>
    );
}