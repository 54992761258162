import React, { useContext, useEffect} from "react"
import { MyContext } from "../../context/MyContext"   // wherever your MyContext are

import styled from 'styled-components';

const StyleSyncBtn = styled.div`
display:flex;
flex-flow:row wrap;
align-items:center;
button{
    border:2px solid #fff;
    border-radius:25px;
    padding:0.5em 2em;
    color:#fff;
    transition-duration: 500ms;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    .unsync_text{
            display: none;
    }
    &:hover{
        background-color:#fff;
        color:#1A1613;
        .address_text{
            display: none;
        }
        .unsync_text{
            display: block;
        }
    }
    
}
`;

export const SyncButton = () => {
    const context = useContext(MyContext)
    const { syncTaquito, disconnect,syncConnect } = useContext(MyContext)

    const handleSyncUnsync = () => {
        if (context.acc?.address) {
            // disconnect wallet
            disconnect()
            console.log(context.acc?.address);
        } else {
            // connect wallet
            syncTaquito().catch(e => e)
        }
    }
    useEffect(() => {
        syncConnect();
      }, [syncConnect])

    return (
        <StyleSyncBtn>
            <button data-tooltip-target="syncTooltip" onClick={() => handleSyncUnsync()} className="wallet"
                data-bs-toggle="tooltip" data-bs-placement="bottom" title={context.address}>
                {context.acc?.address && 
                    <span>
                        <b className="address_text">{context.address.substring(0, 3) + '...' + context.address.substring(context.address.length - 2, context.address.length)}</b>
                        <b className="unsync_text">unsync</b>     
                    </span>
                }
                {!context.acc?.address && <span className="sync_text">Sync</span>}
            </button>
        </StyleSyncBtn>
        
    )
}