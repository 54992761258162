import React from "react";

import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const Styleabout = styled.section`
//padding:4em 2em;
padding: 0 2em 0;
position: relative;
background-color: #fff;
&:before{
    background: url('images/triangle-white.png') no-repeat;
    background-size: 100% auto;
    content:'';
    display: block;
    position: absolute;
    top:0;
    bottom: 0;
    right:0;
    left:0;
    margin: -10.5em auto 0;
}
#about{
    position: absolute;
    top: -160px;
}
.about_wrap{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.about{
    p{
        margin-bottom: 1em;
        line-height: 1.7em;
        color:#707070;
    }
}
.about_left{
    text-align: left;
}

.about_right{
    img{
        width: 100%;
        max-width: 60%;
        margin: 0 auto;
    }
    
}

@media(max-width:991px){
    padding: 0 1em 0;
    &:before{
        margin:-5.5em auto 0;
    }
    .about_wrap{
        flex-flow: column-reverse wrap;
    }
    
}

`;



const About = () =>{
    return(
        <Styleabout>
            <span id="about"></span>
            <Container>
            <Row className="about_wrap">
                <Col col={12} lg={6}>
                    <div className="about_left about">
                    <h2>About<br/>BlockTrain</h2>
                    <p>Have you ever felt this way?<br/>Living in a city for years, you think you’re familiar with here, yet a sense of alienation or rootlessness could still unconsciously be creeping. This sort of vibe grows stronger especially when you’re on a bus/train where the window separates you and the city. A passenger, an alien.</p>
                    <p>Now, we invite you to get on BlockTrain, a train travelling on the Tezos blockchain. Along the way are landscapes built by p5.js. On BlockTrain, you don’t just view from within, but can interact with the scenery outside. You can even decide what to include in the scenery. Everyone’s trip is unique with 1:1 scenery and experience.</p>
                    <p>It’s a trip without a destination. However, you’re not alone this time. Ready for a ride?</p>
                    </div>
                </Col>
                <Col col={12} lg={6}>
                    <div className="about_right about">
                    <img src={process.env.PUBLIC_URL + '/images/cat1.png'} alt="" />
                    </div>
                </Col>
            </Row>
            </Container>
        </Styleabout>
    );
}
export default About