import React from "react"

import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import MyCollectionsSection from '../../components/MyCollections';

const MyCollections = () => {
    return(
        <>
        <Helmet>‍
        <title>BlockTrain - My Collections</title>‍
        <meta name="description" content="Traveling between Dream and Reality" />        
        <meta name="twitter:card" content="" />        
        <meta name="twitter:site" content="BlockTrain" />        
        <meta name="twitter:creator" content="BlockTrain" />        
        <meta name="twitter:title" content="BlockTrain - My Collections" />        
        <meta name="twitter:description" content="Traveling between Dream and Reality" />        
        <meta name="twitter:image" content="https://www.blocktrain.art/images/share.png"/>        
        <meta property="og:title" content="BlockTrain - My Collections" />       
        <meta property="og:description" content="Traveling between Dream and Realityt" />        
        <meta property="og:image" content="https://www.blocktrain.art/images/share.png"/>
        <meta property="og:url" content="https://www.blocktrain.art" />
        <meta property="og:site_name" content="BlockTrain - My Collections" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        </Helmet>
            <motion.div 
                id="page_content"
                className="page_content"
                initial={{opacity:0}}
                animate={{opacity:1}}
                exit={{opacity:0}}
            >
                <MyCollectionsSection />
            </motion.div>
            </>
    );
}


export default MyCollections;
