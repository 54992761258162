import React, { createContext, Component } from 'react'
import { BeaconWallet } from '@taquito/beacon-wallet'
import { TezosToolkit, OpKind, MichelsonMap } from '@taquito/taquito'

var ls = require('local-storage')

export const MyContext = createContext()
// Beacon settings
const nodeUrl = 'https://akaswap.com/tezos';
const networkType = 'mainnet';
const Tezos = new TezosToolkit(nodeUrl);
const wallet = new BeaconWallet({
    name: 'BlockTrain',    // use your app name here
});
Tezos.setWalletProvider(wallet);


export default class MyContextProvider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            address: '',
            Tezos: null,
            wallet: null,
            acc: null,
            setAuth: (address) => {
                ls.set('auth', address)
            },

            updateLs: (key, value) => {
                ls.set(key, value)
            },

            getLs: (key) => {
                return ls.get(key)
            },

            getAuth: () => {
                return ls.get('auth')
            },
            client: null,
            setClient: (client) => {
                this.setState({
                    client: client,
                })
            },
            dAppClient: async () => {
                this.state.client = wallet.client

                // It doesn't look like this code is called, otherwise the active account should be checked, see below.
                this.state.client
                    .requestPermissions({
                        network: {
                            type: networkType,
                            rpcUrl: nodeUrl,
                        },
                    })
                    .then((permissions) => {
                        this.setState({
                            address: permissions.address,
                        })
                        this.state.setAuth(permissions.address)
                    })
                    .catch((error) => console.log(error))
            },
            setAccount: async (done = () => null) => {
                this.setState({
                    acc:
                        Tezos !== undefined
                            ? await wallet.client.getActiveAccount()
                            : undefined,
                    address: await wallet.getPKH(),
                }, () => {
                    done()
                })
            },
            // handle sync
            syncTaquito: async () => {
                const network = {
                    type: networkType,
                    rpcUrl: nodeUrl,
                }
                let activeAccount = await wallet.client.getActiveAccount()
                if (activeAccount === undefined) {
                    await wallet.clearActiveAccount()
                    await wallet.requestPermissions({ network })
                        .then((response) => {
                            console.log('connecting to your wallet')
                            console.log(response)
                        })
                        .catch((e) => console.error(e))
                }
                this.setState({
                    Tezos: Tezos,
                    address: await wallet.getPKH(),
                    acc: await wallet.client.getActiveAccount(),
                    wallet,
                }, () => {
                    this.state.setAuth(this.state.address)
                })
            },
            // handle syncConnect
            syncConnect: async () => {
              
                this.setState({
                    Tezos: Tezos,
                    address: await wallet.getPKH(),
                    acc: await wallet.client.getActiveAccount(),
                    wallet,
                }, () => {
                    this.state.setAuth(this.state.address)
                })
            },
            // handle unsync
            disconnect: async () => {
                console.log('disconnect wallet')
                // This will clear the active account and the next "syncTaquito" will trigger a new sync
                await wallet.client.clearActiveAccount()
                this.setState({
                    address: undefined,
                    acc: undefined,
                }, () => {
                    this.state.setAuth(undefined)
                })
            },
            // handle collect
            // collect: async (market_address, token_amount, swap_id, amount, token_id) => {

            //     if ((market_address === metaverseAddr) | (market_address === metaverseV2Addr)) {
            //         return await Tezos.wallet
            //             .at(market_address)
            //             .then((c) =>
            //                 c.methods.collect(
            //                     parseInt(swap_id),
            //                     parseInt(token_amount)
            //                 ).send({ amount: parseFloat(amount), mutez: true, storageLimit: 1e3 })
            //             )
            //             .catch((e) => e)
            //     }
            //     else {
            //         let c_market = await Tezos.wallet.at(market_address)
            //         let c1 = c_market.methods.acquire_royalties(parseInt(token_id)).toTransferParams()
            //         c1.amount = 0
            //         c1.mutez = true
            //         let c2 = c_market.methods.collect(parseInt(token_amount), parseInt(swap_id)).toTransferParams()
            //         c2.amount = parseFloat(amount)
            //         c2.mutez = true
            //         return await Tezos.wallet.batch([
            //             {
            //                 kind: OpKind.TRANSACTION,
            //                 ...c1,
            //             },
            //             {
            //                 kind: OpKind.TRANSACTION,
            //                 ...c2,
            //             },
            //         ])
            //             .send()
            //     }
            // },
            merge: async (moduleFA2, pixelboardFA2, mergeContract, phase1ID, phase2ID, phase3IDList = [], phase4ID = null, phase5IDList = [], loading = () => null, completed = () => null, getError = () => null) => {
 
                // get current active wallet
                const tz = await wallet.client.getActiveAccount()
                let c_moduleFA2 = await Tezos.wallet.at(moduleFA2)
                let c_pixelboardFA2 = await Tezos.wallet.at(pixelboardFA2)
                let c_merge = await Tezos.wallet.at(mergeContract)


                // module update_operator
                let moduleAddOperators = [
                    {
                        add_operator: {
                            operator: mergeContract,
                            token_id: parseInt(phase1ID),
                            owner: tz.address
                        }
                    },
                    {
                        add_operator: {
                            operator: mergeContract,
                            token_id: parseInt(phase2ID),
                            owner: tz.address
                        }
                    }
                ]
                console.log(moduleAddOperators);
                let moduleRemoveOperators = [
                    {
                        remove_operator: {
                            operator: mergeContract,
                            token_id: parseInt(phase1ID),
                            owner: tz.address
                        }
                    },
                    {
                        remove_operator: {
                            operator: mergeContract,
                            token_id: parseInt(phase2ID),
                            owner: tz.address
                        }
                    }
                ]
                phase3IDList.forEach((tokenID) => {
                    moduleAddOperators.push(
                        {
                            add_operator: {
                                operator: mergeContract,
                                token_id: parseInt(tokenID),
                                owner: tz.address
                            }
                        }
                    )
                    moduleRemoveOperators.push(
                        {
                            remove_operator: {
                                operator: mergeContract,
                                token_id: parseInt(tokenID),
                                owner: tz.address
                            }
                        }
                    )
                })
                if(phase4ID) {
                    moduleAddOperators.push(
                        {
                            add_operator: {
                                operator: mergeContract,
                                token_id: parseInt(phase4ID),
                                owner: tz.address
                            }
                        }
                    )
                    moduleRemoveOperators.push(
                        {
                            remove_operator: {
                                operator: mergeContract,
                                token_id: parseInt(phase4ID),
                                owner: tz.address
                            }
                        }
                    )
                }

                const tModuleAdd = c_moduleFA2.methods.update_operators(moduleAddOperators).toTransferParams({ amount: 0, mutez: true })
                const tModuleRemove = c_moduleFA2.methods.update_operators(moduleRemoveOperators).toTransferParams({ amount: 0, mutez: true })
                // merge function
                let mergeMap = new MichelsonMap();
                mergeMap.set(1, [phase1ID])
                mergeMap.set(2, [phase2ID])
                mergeMap.set(3, phase3IDList)
                mergeMap.set(4, phase4ID? [phase4ID]:[])
                mergeMap.set(5, phase5IDList)
                const tMerge = c_merge.methods.merge(mergeMap).toTransferParams({ amount: 0, mutez: true })
                // pixelboard
                let batchTransaction = []
                if(phase5IDList.length > 0){
                    let pixelboardAddOperators = []
                    let pixelboardRemoveOperators = []
                    phase5IDList.forEach((tokenID) => {
                        pixelboardAddOperators.push(
                            {
                                add_operator: {
                                    operator: mergeContract,
                                    token_id: parseInt(tokenID),
                                    owner: tz.address
                                }
                            }
                        )
                        pixelboardRemoveOperators.push(
                            {
                                remove_operator: {
                                    operator: mergeContract,
                                    token_id: parseInt(tokenID),
                                    owner: tz.address
                                }
                            }
                        )
                    })
                    const tPixelboardAdd = c_pixelboardFA2.methods.update_operators(pixelboardAddOperators).toTransferParams({ amount: 0, mutez: true })
                    const tPixelboardRemove = c_pixelboardFA2.methods.update_operators(pixelboardRemoveOperators).toTransferParams({ amount: 0, mutez: true })
                    batchTransaction = [
                        {
                            kind: OpKind.TRANSACTION,
                            ...tModuleAdd,
                        },
                        {
                            kind: OpKind.TRANSACTION,
                            ...tPixelboardAdd,
                        },
                        {
                            kind: OpKind.TRANSACTION,
                            ...tMerge,
                        },
                        {
                            kind: OpKind.TRANSACTION,
                            ...tModuleRemove,
                        },
                        {
                            kind: OpKind.TRANSACTION,
                            ...tPixelboardRemove,
                        }
                    ]
                }
                else{
                    batchTransaction = [
                        {
                            kind: OpKind.TRANSACTION,
                            ...tModuleAdd,
                        },
                        {
                            kind: OpKind.TRANSACTION,
                            ...tMerge,
                        },
                        {
                            kind: OpKind.TRANSACTION,
                            ...tModuleRemove,
                        }
                    ]
                }
         
                return await Tezos.wallet.batch(batchTransaction)
                    .send()
                    .then((op) => {
                        loading(op.opHash)
                        console.log("Hash : " + op.opHash)
                        op.confirmation()
                            .then((result) => {
                                if (result.completed) {
                                    console.log('Merge Success');
                                    completed(op.opHash)
                                } else {
                                    console.log('An error has occurred');
                                    getError(result)
                                }
                            })
                    })
                    .catch((e) => e)
            },
            split: async (trainFA2, splitContract, trainID, loading = () => null, completed = () => null, getError = () => null) => {
         
                // get current active wallet
                const tz = await wallet.client.getActiveAccount()
                let c_trainFA2 = await Tezos.wallet.at(trainFA2)
                let c_split = await Tezos.wallet.at(splitContract)
         
                // update_operator
                const trainAddOperators = [
                    {
                        add_operator: {
                            operator: splitContract,
                            token_id: parseInt(trainID),
                            owner: tz.address
                        }
                    }
                ]
                const trainRemoveOperators = [
                    {
                        remove_operator: {
                            operator: splitContract,
                            token_id: parseInt(trainID),
                            owner: tz.address
                        }
                    }
                ]
                const tTrainAdd = c_trainFA2.methods.update_operators(trainAddOperators).toTransferParams({ amount: 0, mutez: true })
                const tTrainRemove = c_trainFA2.methods.update_operators(trainRemoveOperators).toTransferParams({ amount: 0, mutez: true })
                // split function
                const tSplit = c_split.methods.split(
                    tz.address, // target
                    parseInt(trainID), // train ID
                    tz.address // train owner
                ).toTransferParams({ amount: 0, mutez: true })
         
                return await Tezos.wallet.batch(
                    [
                        {
                            kind: OpKind.TRANSACTION,
                            ...tTrainAdd,
                        },
                        {
                            kind: OpKind.TRANSACTION,
                            ...tSplit,
                        },
                        {
                            kind: OpKind.TRANSACTION,
                            ...tTrainRemove,
                        }
                    ]
                )
                .send()
                .then((op) => {
                    loading(op.opHash)
                    console.log("Hash : " + op.opHash)
                    op.confirmation()
                        .then((result) => {
                            if (result.completed) {
                                console.log('Split Success');
                                completed(op.opHash)
                            } else {
                                console.log('An error has occurred');
                                getError(result)
                            }
                        })
                })
                .catch((e) => e)
            }
        }
    }
    render() {
        return (
            <MyContext.Provider
                value={{
                    ...this.state
                }}
            >
                {this.props.children}
            </MyContext.Provider>
        )
    }
}