import React from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import PixelBoardSection from "../../components/PixelBoard"


const PixelBoard = () => {

    // const iframeUrl = '/BT_drawBill/index.html';


    return(
        <>
        <Helmet>‍
        <title>BlockTrain - Pixel Board</title>‍
        <meta name="description" content="Traveling between Dream and Reality" />        
        <meta name="twitter:card" content="" />        
        <meta name="twitter:site" content="BlockTrain" />        
        <meta name="twitter:creator" content="BlockTrain" />        
        <meta name="twitter:title" content="BlockTrain - Pixel Board" />        
        <meta name="twitter:description" content="Traveling between Dream and Reality" />        
        <meta name="twitter:image" content="https://www.blocktrain.art/images/share.png"/>        
        <meta property="og:title" content="BlockTrain - Pixel Board" />       
        <meta property="og:description" content="Traveling between Dream and Realityt" />        
        <meta property="og:image" content="https://www.blocktrain.art/images/share.png"/>
        <meta property="og:url" content="https://www.blocktrain.art" />
        <meta property="og:site_name" content="BlockTrain - Pixel Board" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        </Helmet>
            <motion.div 
                id="page_content"
                className="page_content"
                initial={{opacity:0}}
                animate={{opacity:1}}
                exit={{opacity:0}}
            >
                <PixelBoardSection />
                {/* <iframe title="PixelBoard" width="100%" height="600" src={process.env.PUBLIC_URL + iframeUrl } /> */}
            </motion.div>
            </>
    );
}


export default PixelBoard;
