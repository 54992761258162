import React from "react";
import Slider from "react-slick";
import { Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import sideData from './data.json'

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeSliderStyle = styled.section`
    background-color: #1A1613;
    padding:0 2em 4em;
    position: relative;
    #team{
      position: absolute;
      top: -160px;
    }
    h2{
        text-align: center;
        color:#ffffff;
        font-size: 2rem;
        margin-bottom: 2em;
        font-weight: bold;
    }
    @media (max-width:1024px){
      padding:0 2em 1em;
    }
    .slidershow{
      .slick-list{
        .slider_item{
          padding:0 2em;
          .art_img{
            width: 100%;
            max-width:70%;
            margin: 0 auto;
          }
          .art_content{
            padding:2em 0;
            line-height: 1.8em;
          }
          .art_title{
            color:#fff;
            font-size: 1.25rem;
            font-weight: 800;
            padding-bottom: 0.625em;
          }
          .art_info{
            color:#fff;
            font-weight: 400;
          }
        }
      }
      .slick-prev{
        left:-50px;
        z-index: 1;
      }
      .slick-next{
        right:-50px;
        z-index: 1;
      }
      .slick-prev,.slick-next{
        &:before{
          display: none;
        }
      }
    }
    @media (max-width:1800px){
      .slidershow{
        .slick-prev{
          left:-25px;
        }
        .slick-next{
          right:-25px;
        }
      }
    }

    @media (max-width:1500px){
      .slidershow{
        .slick-prev{
          left:0px;
        }
        .slick-next{
          right:0px;
        }
      }
    }

    @media (max-width:1199px){
      .slidershow{
        .slick-prev{
          left:-50px;
        }
        .slick-next{
          right:-50px;
        }
      }
    }

    @media (max-width:1100px){
      .slidershow{
        .slick-prev{
          left:0px;
        }
        .slick-next{
          right:0px;
        }
      }
    }

    @media (max-width:768px){
      .slidershow{
        .slick-list{
          .slider_item{
            padding:0;
            .art_content{
              text-align: center;
            }
          }
        }
        .slick-prev{
          left:-25px;
        }
        .slick-next{
          right:-25px;
        }
      }
    }
`;

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slick-next"
      style={{ display: "block", backgroundColor: "transparent" }}
      onClick={onClick}>
        <img alt="next" src="/images/right.svg" />
      </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slick-prev"
      style={{ display: "block",backgroundColor: "transparent" }}
      onClick={onClick}>
        <img alt="prev" src="/images/left.svg" />
      </div>
  );
}


const  HomeSlider = () => {
  var settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay:true,
    infinite: true,
    autoplaySpeed: 3000,
    speed: 500,
    prevArrow: <PrevArrow/>,
    nextArrow: <NextArrow/>,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        }
      },

    ]
    //prevArrow: <div class="slick-prev prev"><img src="/images/left.svg" /></div>,
    //nextArrow: <div class="slick-next next"><img src="/images/right.svg" /></div>,
  };


    // 載入data
    let items = sideData;
    // 將data跑map() 存入 listItems
    const listItems = items.map((item) =>
        <div key={item.toString()} className="slider_item">
            <img alt="SliderImage" className="art_img" src={process.env.PUBLIC_URL +  item.image} />
            <div className="art_content">
              <h3 className="art_title">{item.title}</h3>
              <p className="art_info">{item.content}</p>
            </div>
        </div>
    );

  return (
    <HomeSliderStyle>
      <span id="team"></span>
        <Container>
            <h2>TEAM</h2>
            <Slider className="slidershow" {...settings}>
                {listItems}
            </Slider>
        </Container>
    </HomeSliderStyle>
  );
}


export default HomeSlider;