import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { MultiAPILink } from "@habx/apollo-multi-endpoint-link";
import { createHttpLink } from "apollo-link-http";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals'
import { GridThemeProvider } from 'styled-bootstrap-grid';

const gridTheme = {
  col: {
    padding: 0, // default 15
  },
  row:{
    padding: 0, // default 15
  },
  container: {
    // padding: 0, // default 15
    maxWidth: { // defaults below
      xl: 1440,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};
const client = new ApolloClient({
  link: ApolloLink.from([
    new MultiAPILink({
        endpoints: {
          objkt: 'https://data.objkt.com/v3',
          fxhash: 'https://api.fxhash.xyz',

        },
        createHttpLink: () => createHttpLink(),
      }),
  ]),
  cache: new InMemoryCache()
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <GridThemeProvider gridTheme={gridTheme}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </GridThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
