import React from "react";
import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const PixelBoardStyle = styled.section`


    background-color: #9FBEC3;

    .bg{
        padding-top:1.5em;
        padding-bottom: 1.5em;
        position: relative;
        @media(max-width:991px){
            padding-bottom: 400px;
        }
    }
    .cat_image{
        position: absolute;
        bottom: 0px;
        right: 0px;
        .cat3{
            position: relative;
            bottom: -32px;
        }
        .cat1{
            position: absolute;
            bottom: -31px;
            right: 200px;
        }

    }

    h1{
        position: relative;
        top: -10px;
        font-size: 2.6rem;

    }
    p{
        margin-bottom: 20px;
        line-height: 28px;
        font-size: 18px;
    }
    ul{
        padding-left: 20px;
        li{
            font-size: 18px;
            line-height: 28px;
            list-style: decimal;
            word-break: break-word;
            ol{
                padding-left: 20px;
                li{
                    list-style: disc;
                }
            }
        }
    }

    .back{
        color:#ffffff;
        text-decoration: underline;
        font-size: 1.5em;
        position: sticky;
        transform: translate(-100px, 30px);
        top: 100px;
        display: inline-block;
    }
    .block{
        margin-bottom: 2em;
        a{
            text-decoration: underline;
        }
    }
    .btn{
        background-color: #ffffff;
        border-radius: 30px;
        padding: 12px 25px;
        min-width: 320px;
        display: inline-block;
        transition: all 0.5s;
        text-align: center;
        text-decoration: none!important;
        &:hover{
            opacity: 0.8;
        }
        svg{
            display: inline-block;
            margin-right: 15px;
            max-width: 25px;
        }
    }
    .image_btn{
        transition: all 0.5s;
        img{
            margin:0 auto;
        }
        &:hover{
            opacity: 0.8;
        }
    }
    @media(max-width:767px){
        .back{
            position: relative;
            transform: translate(0px, 0px);
            top: 0;
            margin-bottom: 20px;
        }
    }

`;




const PixelBoardSection = () => {


    return(
        <PixelBoardStyle>
                
                <Container className="bg">
                    {/* <Link className="back" to="/">{"< back"}</Link> */}
                
                    <Row>
                    
                        <Col col={12} lg={8} >
                        
                            <div className="block">
                                <h1>PixelBoard</h1>
                                <p>Draw your signboard
                                    <br /> Merge your own BlockTrain 
                                    <br />Let’s build our community together.
                                    <br />
                                    <strong>Duration: October 19, 2022 to November 25, 2022</strong>
                                    <br />
                                    <strong>See others' PixelBoards <a rel="noreferrer" target="_blank" href="https://www.akaswap.com/curation/BlockTrainPixelBoard">here</a></strong>
                                </p>
                                <a className="btn" href={process.env.PUBLIC_URL + '/BT_drawBill/index.html'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30.491" height="30.52" viewBox="0 0 30.491 30.52">
                                        <path id="Path_19" data-name="Path 19" d="M29.375,6.509,26.537,9.347,21.064,4.041,23.985,1.12a3.811,3.811,0,1,1,5.389,5.389Zm-4.5,4.5L7.428,28.454,0,30.523l2.037-7.457L19.419,5.684Z" transform="translate(0 -0.004)"/>
                                    </svg>
                                    Create your unique PixelBoard
                                </a>
                            </div>
                            <div className="block">
                                <h2>How to mint PixelBoard</h2>
                                <a rel="noreferrer" target="_blank" className="btn" href={process.env.PUBLIC_URL + '/assets/PixelBoard_howtomint.pdf'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32.491" height="35.879" viewBox="0 0 32.491 35.879">
                                    <path id="Path_18" data-name="Path 18" d="M1,26.409v5.082a3.387,3.387,0,0,0,3.388,3.388H28.1a3.387,3.387,0,0,0,3.388-3.388V26.409M9.47,17.94l6.776,6.776,6.776-6.776M16.246,1V24.715" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                </svg>

                                    Download Pdf file of manual
                                </a>
                            </div>
                            <div className="block">
                                <h2>Rules for PixelBoard Event</h2>
                                <ul>
                                    <li>Mint your pixelboard on 
                                       <a rel="noreferrer" target="_blank" href="https://akaswap.com/mint">
                                       &nbsp;akaSwap</a> .
                                    </li>
                                    <li>Use the template on the official website to produce. (<a rel="noreferrer" target="_blank" href="https://blocktrain.art">https://blocktrain.art</a>) Download the zip file and cover photo from the website. </li>
                                    <li>While minting your PixelBoard, please follow standards below: 
                                        <ol>
                                            <li>Title: Started with “BlockTrain-PixelBoard, ” i.g. “BlockTrain-PixelBoard-example” </li>
                                            <li>Description: free (We suggest adding the URL of BlockTrain:
                                                <a rel="noreferrer" target="_blank" href="https://blocktrain.art">https://blocktrain.art</a>) 
                                            </li>
                                            <li>Tags: BlockTrain,PixelBoard</li>
                                            <li>Editions: 20</li>
                                            <li>Royalty: 20％ </li>
                                            <li>Applying Royalty Sharing: Creator 70%, BlockTrain 25-30%, Processing 0-5% 
                                                <br />BlockTrain Tezos address: tz1PhTDSYV4vk4ARKLCeXSvz7JceQNQdp5js 
                                                <br />Processing Tezos address: tz1aPHze1U5BEEKrGYt3dvY6aAQEeiWm8jjK 
                                            </li>
                                            <li>License: All Rights Reserved</li>
                                            <li>Use frame: Null</li>
                                            <li>Media type: application (Use the template on the official website to produce.<a rel="noreferrer" target="_blank" href="https://blocktrain.art">https://blocktrain.art</a>) </li>
                                            <li>Cover photo: Use the image file provided on the official website.</li>
                                        </ol>
                                    </li>
                                    <li>You can price your PixelBoard at will.</li>
                                    <li>Duration: October 19, 2022 to November 25, 2022</li>
                                    <li>PixelBoards meeting the standards will appear automatically on
                                        &nbsp;<a rel="noreferrer" target="_blank" href="https://akaswap.com/curation/BlockTrainPixelBoard">akaSwap’s curation page</a>.
                                    </li>
                                    <li>Mergeable PixelBoard-NFTs will be those displayed on akaSwap’s curation page.</li>   
                                    <li>The organizer reserves the right for ultimate choices.</li>   
                                </ul>
                            </div>
                        </Col>
                        <Col col={12} lg={4} >
                           <a className="image_btn" href={process.env.PUBLIC_URL + '/BT_drawBill/index.html'}>
                                <img src={process.env.PUBLIC_URL + '/images/go_to_pixel_board.png'} alt="" />
                           </a>
                        </Col>
                    </Row>
                    <div className="cat_image">
                        <img className="cat3" src={process.env.PUBLIC_URL + '/images/cat3.png'} alt="" />
                        <img className="cat1" src={process.env.PUBLIC_URL + '/images/cat1.png'} alt="" />
                    </div>
                   
                </Container>
        </PixelBoardStyle>
    );
}


export default PixelBoardSection;
