import React,{useContext} from "react";
import { MyContext } from "../../context/MyContext";
import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { PassSection, WindowSection, BuildingSection, ObjectSection, AtmosphereSection, BlockTrainSection, PixelBoardSection } from './nftSection';
import NoNftSection from './noNftSection'

const CollectionsStyle = styled.section`
    background-color: #1A1613;
    border-bottom: 1px solid #fff;
    min-height: 500px;
    h1.title{
        color:#ffffff;
    }
    p.desc{
        color:#ffffff;
    }
    .right_block{
        text-align: right;
        a{
            margin-right: 15px;
            color:#000;
            box-shadow:0 2px 14px -4px rgb(0 0 0 / 67%);
            border:1px solid #c8ff00;
            padding:10px 20px;
            display: inline-block;
            background-color:#c8ff00;
            min-width: 150px;
            text-align: center;
            transition: 0.5s all;
            font-weight: bold;
            &:hover{
                opacity:0.8;
            }
        }
    }
    @media(max-width:767px){
       .button_group{
           margin-top:2em;
           text-align: left;
       }
    }
`;
const SectionStyle = styled.section`
    padding-top:20px;
    padding-bottom: 20px;
    a{
        color:#ffffff;
        text-decoration: underline;
        margin-bottom: 15px;
        display: inline-block;
    }
    img{
        border:1px solid #ffffff;
    }
    p{
        color:#ffffff;
    }
    .card_row{
        margin-left: -15px;
        margin-right: -15px;
    }
    .card{
        padding:0px 15px;
    }
`;



const MyCollectionsSection = () => {

    const context = useContext(MyContext)

    return(
        <CollectionsStyle>
                <Container>
                    <Row>
                        <Col col={12} lg={6} >
                            <h1 className="title">My Collections</h1>
                            {!context.acc?.address && <p className="desc">Please Sync with wallet to view your collections.</p>}
                        </Col>
                        <Col col={12} lg={6} className="right_block">
                           <div className="button_group">
                                <Link to="/merge">{'Merge >>'}</Link>
                           </div>
                        </Col>

                    </Row>
                    <NoNftSection />

                    <SectionStyle>
                        <BlockTrainSection />
                    </SectionStyle>  
                    <SectionStyle>
                        <WindowSection />
                    </SectionStyle>
                    <SectionStyle>
                        <BuildingSection />
                    </SectionStyle>
                    <SectionStyle>
                        <ObjectSection />
                    </SectionStyle>
                    <SectionStyle>
                        <AtmosphereSection />
                    </SectionStyle>
                    <SectionStyle>
                        <PixelBoardSection />
                    </SectionStyle>
                    <SectionStyle>
                        <PassSection />
                    </SectionStyle>
                     
                </Container>
        </CollectionsStyle>
    );
}


export default MyCollectionsSection;
