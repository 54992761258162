import React, {useState} from "react";
import styled from 'styled-components';
import { Link, NavLink } from "react-router-dom";


const Burger = styled.div`
    width:2rem;
    height:2rem;
    display: inline-flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 20;
    display: none;
    @media (max-width: 991px) {
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
    }
    
    span {
        width: 2rem;
        height: 0.25rem;
        background-color: ${({ open }) => open ? '#fff' : '#fff'};
        transform-origin: 1px;
        transition: all 0.3s linear;
        
        &:nth-child(1) {
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }
        
        &:nth-child(2) {
            transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
            opacity: ${({ open }) => open ? 0 : 1};
        }
        
        &:nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
    `;

const Navigation = styled.nav`
display: flex;
flex-flow: row nowrap;
ul{
    display: flex;
    li{
        text-align: left;
        color:#fff;
        margin-right: 1.5em;
        &:hover{
            opacity:0.8;
            transition:0.5s all;
        }
    }
    .m_show{
        display: none;
    }
}
@media (max-width: 991px) {
    position: fixed;
    background-color:#000;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80%;
    max-width:300px;
    padding: 5.5em 1em;
    transition: transform 0.3s ease-in-out;
    transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(-100%)'};
    opacity: ${({ open }) => open ? '1' : '0'};
    ul{
        flex-flow: column nowrap;
        li{
            padding:0.625em;
        }
        .m_show{
            display: block;
        }
    }
}
`;

const  Nav = () => {
    const [open, setOpen] = useState(false)
    return(
        <>
    <Burger id="hamburger" open={open} onClick={() => setOpen(!open)}>
        <span />
        <span />
        <span />
    </Burger>
    <Navigation id="nav" open={open}>
        <ul>
            <li className="mr-6">
                <a
                    onClick={() => setOpen(!open)}
                    href="/"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20.024" viewBox="0 0 18 20.024">
                    <g id="Group_27" data-name="Group 27" transform="translate(-83 88)">
                        <path id="home" d="M20,8h0L14,2.74a3,3,0,0,0-4,0L4,8a3,3,0,0,0-1,2.26V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V10.25A3,3,0,0,0,20,8ZM14,20H10V15a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Zm5-1a1,1,0,0,1-1,1H16V15a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3v5H6a1,1,0,0,1-1-1V10.25a1,1,0,0,1,.34-.75l6-5.25a1,1,0,0,1,1.32,0l6,5.25a1,1,0,0,1,.34.75Z" transform="translate(80 -89.976)" fill="#fff"/>
                    </g>
                    </svg>
                </a>
            </li>
            <li className="mr-6">
                <Link
                onClick={() => setOpen(!open)}
                to="/#about"
                >
                    About
                </Link>
            </li>
            <li className="mr-6">
                <Link
                onClick={() => setOpen(!open)}
                to="/#team"
                >
                    Team
                </Link>
            </li>
            <li className="mr-6">
                <NavLink
                onClick={() => setOpen(!open)}
                to="/how_to_merge" >
                    How to Merge
                </NavLink>
            </li>
            <li className="mr-6" >
                <Link
                onClick={() => setOpen(!open)}
                to="/#faq"
                >
                    FAQ
                </Link>
            </li>
            <li className="mr-6" >
                <Link
                onClick={() => setOpen(!open)}
                to="/pixel_board"
                >
                    PixelBoard
                </Link>
            </li>
            <li className="mr-6 m_show">
                <NavLink
                onClick={() => setOpen(!open)}
                to="/collections" >
                    My Collections
                </NavLink>
            </li>
        </ul>
    </Navigation>
    </>
    );
}
export default Nav