
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import ControlMain from "./ControlMain";
import { Container, Row, Col ,media} from 'styled-bootstrap-grid';
import styled from 'styled-components';

var ls = require('local-storage')

const SimulatorStyle = styled.section`
padding:2em 0 18em;
background-color:#1A1613;
margin-top: -1px;
@media (max-width:768px) {
    padding:2em 1em 10em;
    
}

    .control_top{
        margin-bottom: 1.5em;
    }
    .title{
        //font-size: 2rem;
        color:#ffffff;
        text-align: left;
        padding-bottom: 0px;
        line-height: 1em;
        margin-bottom: 5px;
        a{
            text-decoration: underline;
        }
    }
    .desc{
        font-size: 18px;
        color:#ffffff;
        text-align: left;
        ${media.xs`
            margin-bottom:15px;
        `}
    }
    .right_block{
        text-align: right;
        @media(max-width:991px){
            text-align:left;
            margin-top:20px;
            margin-bottom:20px;
        }
        span{
            color:#ffffff;
          
        }
    }
    .button_wrap{
        display: inline-block;
    }
    .btn_view{
            background-color: #000000;
            border: 2px solid #ffffff;
            color: #ffffff;
            border-radius: 25px;
            padding: 10px 0;
            margin-left: 15px;
            margin-right: 15px;
            min-width: 150px;
            display: inline-block;
            text-align: center;
            -webkit-transition: 0.5s all;
            transition: 0.5s all;
            &:hover{
                background-color:#fff;
                color:#000;
                -webkit-transition: 0.5s all;
                transition: 0.5s all;
            }
            @media(max-width:991px){
                margin-left: 0px;
                margin-right:10px;
            }
            @media (max-width:376px) {
                margin-bottom:1em;                
            }
        }
    .btn_go{
                color:#000000;
                background-color: #ffffff;
                border: 1px solid #ffffff;
                min-width: 150px;
                border-radius: 25px;
                padding: 10px 0;
                transition: 0.5s all;
                position: relative;
                display:inline-block;
                cursor: pointer;
                text-align: center;
                &::before{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border-radius: 25px;
                    border: 3px solid #000000;
                    padding:1px;
                }
                &:hover{
                opacity: 0.8;
            }
        }
        .random{
            text-align:center;
            margin-top:30px;
            p{
                color:#ffffff;
                margin-top:30px;
               
                b{
                    text-decoration:underline;
                }
            }
        }
        .demo_block{
            cursor: pointer;
            transition:all 0.5s;
            img{
                &:hover{
                opacity:0.8;
                }
            } 
        }
        .mute_control{
            display: flex;
            align-items: center;
            position: absolute;
            right: 20px;
            top: 20px;
            .mute_btn{
                &.active{
                    display: none;
                }
            }
            .mute{
                width:100%;
                max-width:2em;
                
            }
        }
    .mobile_show{
        display:none;
    }
    @media (max-width:991px){
        .title{
            padding-bottom:0.2em;
        }
    }

    @media (max-width:768px){
            .button_wrap{
                margin-left:10px;
                .btn_go{
                display:none;
                }
            }
            .mobile_show{
                display:block;
                text-align: center;
            }
            .pc_show{
                display:none
            }
        }
    .arrow{
        padding-top:2em;
        padding-bottom:2em;
        text-align:center;
        svg{
            max-width:30px;
            margin:0 auto;
        }
    }
    .result_block{
        margin-top:30px;
        position:relative;
    }
   
`;



const Simulator = () => {
    function FS_rand(a, b) {
        const var_min = Math.min(a, b);
        const var_max = Math.max(a, b);
        //return fxrand()*(var_max-var_min) + var_min;
        return Math.random()*(var_max-var_min) + var_min;
    }

    function countProb(number) {
        let real_rate = new Array(number.length);
        let sum = 0;
        for (let i=0; i<number.length; i++) {
          sum += number[i];
        }
      
        for (let i=0; i<number.length; i++) {
          real_rate[i] = number[i] / sum;
        }
      
        const r = FS_rand(0, 1);
      
        let s_real_rate = 0;
        let index_final = 0;
        for (let i=0; i<number.length; i++) {
          s_real_rate += real_rate[i];
          if (r < s_real_rate) {
            index_final = i;
            break;
          }
        }
      
        return index_final;
      }

    const iframeUrl = '/metro_221020_web_all03/index.html';
    const [mutedVideo, setMutedVideo] = useState(false);
    const refIframe = useRef(null);
    const [iframSrc, setIframeUrl] = useState('');
    const [iframBlock, setiframBlock] = useState(false);

    const buildFrame = () => {
        const windowInput = document.getElementById('window').value;
        const buildingInput = document.getElementById('Building').value;

        let err = '';
        if(windowInput === ''){
            err += 'Please enter window field\n'
        }
        if(buildingInput === ''){
            err += 'Please enter building field\n'
        }

       
        if (err !== '') {
            alert(err);
            return;
        }
        
        let dt = new Date().getTime();
        setIframeUrl(
            `${iframeUrl+'?'+dt}`
          );
        // 頁面載入模擬器暫存
        const windowData =  ls.get('WindowData')
        const buildingData =  ls.get('BuildingData')
        const objData =  ls.get('ObjData')
        const AtmosphereData =  ls.get('Atmosphere')
        const PixelBoardTxt =  ls.get('PixelBoardTxt')
        
        let PixelBoardValues = Array.prototype.slice.call(document.querySelectorAll('.Pixel_Board_select option:checked'),0).map(function(v,i,a) { 
            return v.value; 
        });
        const PixelBoardTxtArray = []
        const PixelBoardDataIn = []
        // ls抓資料跑回圈 組裝 PixelBoardTxtArray
        for (var key in PixelBoardTxt) {
            PixelBoardTxtArray.push({
                [PixelBoardTxt[key].tokenId] : PixelBoardTxt[key].data 
            })
          }
        // 將選中的跑循環獲取PBdata
        for (var key in PixelBoardTxtArray) {
            
            if(PixelBoardTxtArray[key][PixelBoardValues[0]] !== undefined){
                PixelBoardDataIn.push({
                    '0': PixelBoardTxtArray[key][PixelBoardValues[0]],
                    '1': ""
                })
            }
            if(PixelBoardTxtArray[key][PixelBoardValues[1]] !== undefined){
                PixelBoardDataIn.push({
                    '0': PixelBoardTxtArray[key][PixelBoardValues[1]],
                    '1': ""
                })
            }
            if(PixelBoardTxtArray[key][PixelBoardValues[2]] !== undefined){
                PixelBoardDataIn.push({
                    '0': PixelBoardTxtArray[key][PixelBoardValues[2]],
                    '1': ""
                })
            }
            if(PixelBoardTxtArray[key][PixelBoardValues[3]] !== undefined){
                PixelBoardDataIn.push({
                    '0': PixelBoardTxtArray[key][PixelBoardValues[3]],
                    '1': ""
                })
            }
            if(PixelBoardTxtArray[key][PixelBoardValues[4]] !== undefined){
                PixelBoardDataIn.push({
                    '0': PixelBoardTxtArray[key][PixelBoardValues[4]],
                    '1': ""
                })
            }
            if(PixelBoardTxtArray[key][PixelBoardValues[5]] !== undefined){
                PixelBoardDataIn.push({
                    '0': PixelBoardTxtArray[key][PixelBoardValues[5]],
                    '1': ""
                })
            }
            if(PixelBoardTxtArray[key][PixelBoardValues[6]] !== undefined){
                PixelBoardDataIn.push({
                    '0': PixelBoardTxtArray[key][PixelBoardValues[6]],
                    '1': ""
                })
            }
            if(PixelBoardTxtArray[key][PixelBoardValues[7]] !== undefined){
                PixelBoardDataIn.push({
                    '0': PixelBoardTxtArray[key][PixelBoardValues[7]],
                    '1': ""
                })
            }
            if(PixelBoardTxtArray[key][PixelBoardValues[8]] !== undefined){
                PixelBoardDataIn.push({
                    '0': PixelBoardTxtArray[key][PixelBoardValues[8]],
                    '1': ""
                })
            }
          }
        console.log(PixelBoardDataIn);
        // console.log(PixelBoardTxtArray);

        const Phase01 =  {};
        const Phase02 =  {};
        let Phase01WindowNumber = {
                // 車窗 
                "Shape":{
                    'HOR': 0, 'VER': 1, "SQUARE": 2,"STRIP":3,"STADIUM":4,"CIRCLE":5,"DOUBLE":6,"TRIPLE":7,"SCREEN":8,"WINSWIN":9,"NOISEY":10,"MESSAGE":11,"ARCH":12,"HALF":13,"CROSS":14,"SLIP":15,"EYE":16
                },
                // 窗框
                "Decoration":{
                    'NULL':0 , 'SUBMARINE':1, "PLANE":2,"SHUTTER":3,"CURTAIN":4,"MESS":5,"HANDLE":6,"BLINK":7,"FLASH":8,"FAN":9,"HOLIDAY":10
                },
                // 背景顏色
                "Color":{
                    "DEFULT":0, "SHINING":1 ,"HER":2,"FAIRY":3,"BUDAPEST":4,"DECKARD":5,"JOEL":6,"RUNNER":7,"DALLAS":8,"VANILLA":9,"FORBIDDEN":10,"K":11,"JACKHOUSE":12,"TYLER":13,"MIX1":14,"MIX2":15,"MIX3":16,"DSO1":17,"DSO2":18
                },
                // 城市密集度
                "Density":{
                   "HANDSHAKE":0 ,"DENSE":1 ,"NORMAL":2,"SPARSE":3
                },
                // 馬路寬度
                "RoadWidth":{
                   "NARROW":0 ,"DEFULT":1 ,"WIDE":2
                },
                // 軌道位置/離城市的遠近
                "ViewHeight":{
                   "LOW":0 ,"DEFULT":1 ,"HIGH":2,"HIGH+":3,"HIGH++":4
                },
                // 行駛速度
                "Speed":{
                   "LAZY":0 ,"SLOW":1 ,"NORMAL":2,"FAST":3,"FAST+":4,"HURRY":5
                },
                

             };
        windowData.forEach((obj) => {
            if(obj.name === "Shape"){
                Phase01[obj.name] = Phase01WindowNumber.Shape[obj.value];
            }
            else if(obj.name === "Decoration"){
                Phase01[obj.name] = Phase01WindowNumber.Decoration[obj.value];
            }
            else if(obj.name === "Color"){
                Phase01[obj.name] = Phase01WindowNumber.Color[obj.value];
            }
            else if(obj.name === "Density"){
                Phase01[obj.name] = Phase01WindowNumber.Density[obj.value];
            }
            else if(obj.name === "RoadWidth"){
                Phase01[obj.name] = Phase01WindowNumber.RoadWidth[obj.value];
            }
            else if(obj.name === "ViewHeight"){
                Phase01[obj.name] = Phase01WindowNumber.ViewHeight[obj.value];
            }
            else if(obj.name === "Speed"){
                Phase01[obj.name] = Phase01WindowNumber.Speed[obj.value];
            }
            
         });

        let Phase02buildingNumber = {
            // 環境
            "Landscape":{
                'DEFAULT': 0, 'DESERT': 1, "FLOOD": 2,"SEA":3,"MOUNTAIN":4
            },
            // 樓房排列
            "Arrange":{
                'DEFAULT': 0, 'STAIR': 1, "EMBANKMENT": 2,"SKYSCRAPER":3,"SUGARCUBE":4
            },
            // 大機率出現的房子1
            "House_Usual_1":{
                'NULL': 0, 'CONSTR': 1, "CAT": 2,"WALL":3,"FIREWATCH":4,"BLOCK":5,"DOTTED":6,"ORIENT":7,"UNDER":8
            },
            // 大機率出現的房子2
            "House_Usual_2":{
                'NULL': 0, 'CONSTR': 1, "CAT": 2,"WALL":3,"FIREWATCH":4,"BLOCK":5,"DOTTED":6,"ORIENT":7,"UNDER":8
            },
            // 小機率出現的房子1
            "House_Rare_1":{
                'NULL': 0, 'CONSTR': 1, "CAT": 2,"WALL":3,"FIREWATCH":4,"BLOCK":5,"DOTTED":6,"ORIENT":7,"UNDER":8
            },
            // 小機率出現的房子2
            "House_Rare_2":{
                'NULL': 0, 'CONSTR': 1, "CAT": 2,"WALL":3,"FIREWATCH":4,"BLOCK":5,"DOTTED":6,"ORIENT":7,"UNDER":8
            },
            // 特殊街道
            "SpecialStreet":{
                'NULL': 0, 'RAILWAY': 1, "RIVER": 2,"NIGHTMARKET":3
            },
         };
        buildingData.forEach((obj) => {
        if(obj.name === "Landscape"){
            Phase02[obj.name] = Phase02buildingNumber.Landscape[obj.value];
        }
        else if(obj.name === "Arrange"){
            Phase02[obj.name] = Phase02buildingNumber.Arrange[obj.value];
        }
        else if(obj.name === "House_Usual_1"){
            Phase02[obj.name] = Phase02buildingNumber.House_Usual_1[obj.value];
        }
        else if(obj.name === "House_Usual_2"){
            Phase02[obj.name] = Phase02buildingNumber.House_Usual_2[obj.value];
        }
        else if(obj.name === "House_Rare_1"){
            Phase02[obj.name] = Phase02buildingNumber.House_Rare_1[obj.value];
        }
        else if(obj.name === "House_Rare_2"){
            Phase02[obj.name] = Phase02buildingNumber.House_Rare_2[obj.value];
        }
        else if(obj.name === "SpecialStreet"){
            Phase02[obj.name] = Phase02buildingNumber.SpecialStreet[obj.value];
        }
        
     });

     let Phase03objNumber = {
        "Object":{
            '/': 0, 'ROOFTOP_SHANTY': 1, "ROOFTOP_RAILING": 2,"ROOFTOP_CROSS":3,"BRIDGE":4,"AD_MC":5,"AD_YT":6,"AD_XTZ":7,"AD_BTC":8,"AD_ETH":9,"AD_MESSAGE":10,
            "AD_LIKE":11,"AD_CN":12,"AD_CALL":13,"AD_POWER":14,"AD_METRO":15,"AD_FILE":16,"AD_PLANE":17,"PARK":18,"FAIR":19,"TREE":20,"DEADTREE":21,"GAZEBO":22,
            "PAIFANG":23,"TOWER":24
        }
     };
     let Phase04AtmosphereNumber = {
        "Weather":{
            'NULL': 0, 'RAIN': 1, "WATERDROP": 2,"MIST":3,"DARKCLOUD":4,"SUNBEAM":5,"TYPHOON":6,"TORNADO":7,"SNOW":8,"FIREWORK":9,"HUGEMOON":10,
            "RAIN+WATERDROP":11,"RAIN+DARKCLOUD":12,"SNOW+SUNBEAM":13,"SNOW+MIST":14,"SNOW+FIREWORK":15,"TYPHOON+WATERDROP":16,"RAIN+MIST+WATERDROP":17
        },
        "trueOrfalse":{
           true:1,
           false:0
        }
     };
     let AtmosphereWeather = 0
     let AtmosphereButton_Stop = 0
     let AtmosphereButton_Sprint = 0
     let AtmosphereButton_Jump = 0
     let AtmosphereButton_Regenerate = 0
     let AtmosphereButton_Shine = 0
     let AtmosphereButton_Submerge = 0
     let AtmosphereButton_Spring = 0
     let AtmosphereButton_Invert = 0

    //  判斷天氣控制項是不是有被執行過
     if(AtmosphereData === null){
        AtmosphereWeather = 0
        AtmosphereButton_Stop = 0
        AtmosphereButton_Sprint = 0
        AtmosphereButton_Jump = 0
        AtmosphereButton_Regenerate = 0
        AtmosphereButton_Shine = 0
        AtmosphereButton_Submerge = 0
        AtmosphereButton_Spring = 0
        AtmosphereButton_Invert = 0
     }else{
        AtmosphereWeather = Phase04AtmosphereNumber.Weather[AtmosphereData[0].value]
        AtmosphereButton_Stop = Phase04AtmosphereNumber.trueOrfalse[AtmosphereData[2].value]
        AtmosphereButton_Sprint = Phase04AtmosphereNumber.trueOrfalse[AtmosphereData[3].value]
        AtmosphereButton_Jump = Phase04AtmosphereNumber.trueOrfalse[AtmosphereData[4].value]
        AtmosphereButton_Regenerate = Phase04AtmosphereNumber.trueOrfalse[AtmosphereData[1].value]
        AtmosphereButton_Shine = Phase04AtmosphereNumber.trueOrfalse[AtmosphereData[8].value]
        AtmosphereButton_Submerge = Phase04AtmosphereNumber.trueOrfalse[AtmosphereData[6].value]
        AtmosphereButton_Spring = Phase04AtmosphereNumber.trueOrfalse[AtmosphereData[7].value]
        AtmosphereButton_Invert = Phase04AtmosphereNumber.trueOrfalse[AtmosphereData[5].value]
     }
     

        const data = {
            "mainVar": {
              Phase01,
              Phase02,
              "Phase03": {
                "Object_First": Phase03objNumber.Object[objData[0].data],
                "Object_Second": Phase03objNumber.Object[objData[1].data],
                "Object_Third": Phase03objNumber.Object[objData[2].data],
              },
              "Phase04": {
                "Weather": AtmosphereWeather,
                "Button": {
                  "Button_Stop": AtmosphereButton_Stop,
                  "Button_Sprint": AtmosphereButton_Sprint,
                  "Button_Jump": AtmosphereButton_Jump,
                  "Button_Regenerate": AtmosphereButton_Regenerate,
                  "Button_Shine": AtmosphereButton_Shine,
                  "Button_Submerge": AtmosphereButton_Submerge,
                  "Button_Spring": AtmosphereButton_Spring,
                  "Button_Invert": AtmosphereButton_Invert,
                }
              }
            }
        };
        console.log(data);
        document.main_data=data;
        document.PixelBoardData=PixelBoardDataIn;
        setiframBlock(true)
        setMutedVideo(false);
    }
    const loadIframe = () => {
        
        document.getElementById("KV_Video").muted = true;
         let dt = new Date().getTime();
         setIframeUrl(
             `${iframeUrl+'?'+dt}`
           );
        const data = {
            "mainVar": {
              "Phase01": {
                "Shape": countProb([18, 9, 12, 9, 11, 11, 7, 5, 1.5, 1.5, 1, 1, 4, 2, 3, 2, 2]),
                "Decoration": countProb([20, 18, 12, 12, 10, 2, 5, 5, 5, 3, 8]),
                "Color": countProb([3.2, 6.6, 6.6, 6.6, 6.6, 6.6, 6.6, 6.6, 6.6, 6.6, 6.6, 6.6, 6.6, 6.6, 3, 3, 3, 1, 1]),
                "Density": countProb([15, 35, 35, 15]),
                "RoadWidth": countProb([30, 50, 20]),
                "ViewHeight": countProb([15, 55, 20, 7, 3]),
                "Speed": countProb([6, 27, 34, 20, 12, 1])
              },
              "Phase02": {
                "Landscape": countProb([38, 13, 7, 24, 18]),
                "Arrange": countProb([35, 20, 25, 15, 5]),
                "House_Usual_1": countProb([7, 20, 10, 15, 18, 10, 5, 14, 1]),
                "House_Usual_2": countProb([50, 9, 4, 9, 9, 6, 4, 8, 1]),
                "House_Rare_1": countProb([20, 15, 13, 10, 15, 7, 5, 14, 1]),
                "House_Rare_2": countProb([60, 8, 5, 7, 7, 2, 2, 8, 1]),
                "SpecialStreet": countProb([50, 12, 19, 19])
              },
              "Phase03": {
                "Object_First": countProb([0, 4, 4, 3, 4, 4, 4, 6, 6, 6, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 2.5, 2.5, 4, 5, 5]),
                "Object_Second": countProb([0, 4, 4, 3, 4, 4, 4, 6, 6, 6, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 2.5, 2.5, 4, 5, 5]),
                "Object_Third": countProb([0, 4, 4, 3, 4, 4, 4, 6, 6, 6, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 2.5, 2.5, 4, 5, 5])
              },
              "Phase04": {
                "Weather": countProb([10.5, 8, 8, 8, 8, 8, 8, 8, 8, 6, 7, 3, 3, 3, 3, 3, 2, 1.5]),
                "Button": {
                  "Button_Stop": countProb([85, 15]),
                  "Button_Sprint": countProb([85, 15]),
                  "Button_Jump": countProb([85, 15]),
                  "Button_Regenerate": countProb([87, 13]),
                  "Button_Shine": countProb([89, 11]),
                  "Button_Submerge": countProb([90, 10]),
                  "Button_Spring": countProb([89, 11]),
                  "Button_Invert": countProb([89, 11])
                }
              }
            }
        };
        console.log(data);
        // document.main_data=data;
        document.main_data=data;
        setiframBlock(true)
        setMutedVideo(false);
    }
     const muteOpen = () => {
         setMutedVideo(true);

         const data = {
            "data" : false,
            "control": "Muted"
          }

         const childFrameObj = document.getElementById('result_iframe');
         childFrameObj.contentWindow.postMessage(data);
     }
     const muteClose = () => {
        setMutedVideo(false);

         const data = {
            "data" : true,
            "control": "Muted"
          }
       
        
         const childFrameObj = document.getElementById('result_iframe');
         childFrameObj.contentWindow.postMessage(data);
     }

    return(
        <SimulatorStyle>
            <Container>
                    <Row className="control_top" alignItems={"center"}>
                        <Col col={12} md={6}>
                            <Row alignItems={"end"}>
                                <Col col={12} xl={3} lg={5}>
                                    <h2 className="title">
                                        BlockTrain<br></br>
                                        Simulator
                                    </h2>
                                </Col>
                                <Col col={12} xl={8} lg={6} lgOffset={1}>
                                    <p className="desc">
                                    Insert the NFT numbers of specific phases that you like (i.g. BlockTrain Window #1), press "simulate" to see your merged BlockTrain NFT!
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="right_block" col={12} md={6}>
                            {/* <span>{'Search for NFTs >'}</span> */}
                            <div className="button_wrap">
                                <Link className="btn_view" to="/merge" href="https://www.fxhash.xyz/u/BlockTrain"  rel="noreferrer" target="_blank">GO Merge</Link>
                                <Link className="btn_go pc_show"   to="#result_block_a"  onClick={buildFrame}>{'Simulate >>'}</Link>
                            </div>

                        </Col>
                    </Row>
                    <ControlMain />
                    <div className="arrow" id="result_block_a">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.414" height="47.414" viewBox="0 0 17.414 47.414">
                        <path id="arrow1" data-name="arrow (1)" d="M1,26H44.586l-6.293,6.293,1.414,1.414L48.414,25l-8.707-8.707-1.414,1.414L44.586,24H1Z" transform="translate(33.707 -1) rotate(90)" fill="#fff"/>
                    </svg>
                    </div>
                    <div className="mobile_show">
                        <Link className="btn_go"   to="#result_block_b"  onClick={buildFrame}>{'Simulate >>'}</Link>
                        <div className="arrow " id="result_block_b">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.414" height="47.414" viewBox="0 0 17.414 47.414">
                                <path id="arrow1" data-name="arrow (1)" d="M1,26H44.586l-6.293,6.293,1.414,1.414L48.414,25l-8.707-8.707-1.414,1.414L44.586,24H1Z" transform="translate(33.707 -1) rotate(90)" fill="#fff"/>
                            </svg>
                        </div>
                        
                    </div>
                 <div onClick={buildFrame} className="demo_block" style={{display: iframBlock ? 'none' : 'block' }}>
                     <img src={process.env.PUBLIC_URL + '/images/iframe_demo.png'} alt="" />
                 </div>
                 <div className="result_block" id="result_block" style={{display: iframBlock ? 'block' : 'none' }}>
                     <div className="mute_control">
                        <button onClick={muteClose} className={"mute_btn " + ( !mutedVideo ? "active" : "")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.061" height="26.036" viewBox="0 0 28.061 26.036">
                            <path id="mute" d="M21.05,3.18A2,2,0,0,0,19,3.35L8.56,10.91H5.12a3.06,3.06,0,0,0-3.12,3v4a3.07,3.07,0,0,0,3.13,3H8.56L19,28.62h0A1.955,1.955,0,0,0,22.14,27V5a2,2,0,0,0-1.09-1.82ZM5.12,18.94a1.07,1.07,0,0,1-1.12-1v-4a1.06,1.06,0,0,1,1.13-1H7.89v6Zm15,8.07L9.89,19.41v-7L20.14,5V27ZM28.33,16l1.42-1.63a1,1,0,1,0-1.5-1.31L27,14.48l-1.25-1.42a1,1,0,1,0-1.5,1.31L25.67,16l-1.42,1.63a.983.983,0,1,0,1.5,1.27L27,17.52l1.25,1.42a1,1,0,1,0,1.5-1.31Z" transform="translate(-2 -2.974)" fill="#fff"/>
                        </svg>
                        </button>
                        <button onClick={muteOpen} className={"mute_btn " + (mutedVideo ? "active" : "")}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.14" height="26.036" viewBox="0 0 20.14 26.036">
                            <path id="mute" d="M21.05,3.18A2,2,0,0,0,19,3.35L8.56,10.91H5.12a3.06,3.06,0,0,0-3.12,3v4a3.07,3.07,0,0,0,3.13,3H8.56L19,28.62h0A1.955,1.955,0,0,0,22.14,27V5a2,2,0,0,0-1.09-1.82ZM5.12,18.94a1.07,1.07,0,0,1-1.12-1v-4a1.06,1.06,0,0,1,1.13-1H7.89v6Zm15,8.07L9.89,19.41v-7L20.14,5V27Z" transform="translate(-2 -2.974)" fill="#fff"/>
                        </svg>
                        </button>
                     </div>
                    
                    <div className="iframe_obj">
                        <iframe
                            id="result_iframe"
                            title="resultFrame"
                            src={process.env.PUBLIC_URL + iframSrc }
                            width="100%"
                            height="600"
                            ref={refIframe}
                        />
                    </div>
                    <div className="random">
                        <Link className="btn_go"  to="#result_block_a"  onClick={loadIframe}>{'Random >>'}</Link>
                        <p>You can merge as long as you have NFTs of <b>Phase 1 & 2</b></p>
                    </div>
                    
                </div> 
            </Container>
        </SimulatorStyle>
    );
}

export default Simulator;

