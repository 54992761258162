
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ControlMain from "./ControlMain";
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { MyContext } from "../../context/MyContext";
import styled from 'styled-components';
// import SplitControl  from "./splitToken";

// var ls = require('local-storage')

const MergeStyle = styled.section`
padding:2em 0 18em;
background-color:#1A1613;
margin-top: -1px;
@media (max-width:768px) {
    padding:2em 1em 10em;
    
}

    .control_top{
        margin-bottom: 1.5em;
    }
    .title{
        //font-size: 2rem;
        color:#ffffff;
        text-align: left;

    }
    .desc{
        font-size: 18px;
        color:#ffffff;
        text-align: left;
        a{
            text-decoration: underline;
        }
    }
    .right_block{
        text-align: right;
        @media(max-width:991px){
            text-align:left;
            margin-top:20px;
            margin-bottom:20px;
        }
        span{
            color:#ffffff;
          
        }
    }
    .button_wrap{
        display: inline-block;
    }
    .btn_view{
            background-color: #000000;
            border: 2px solid #ffffff;
            color: #ffffff;
            border-radius: 25px;
            padding: 10px 0;
            margin-left: 15px;
            margin-right: 15px;
            min-width: 150px;
            display: inline-block;
            text-align: center;
            -webkit-transition: 0.5s all;
            transition: 0.5s all;
            &:hover{
                background-color:#fff;
                color:#000;
                -webkit-transition: 0.5s all;
                transition: 0.5s all;
            }
            @media(max-width:991px){
                margin-left: 0px;
                margin-right:10px;
            }
            @media (max-width:376px) {
                margin-bottom:1em;                
            }
        }
    .btn_go{
                background-color:#c8ff00;
                min-width: 150px;
                text-align: center;
                box-shadow:0 2px 14px -4px rgb(0 0 0 / 67%);
                min-width: 150px;
                font-weight: bold;
                padding: 10px 0;
                transition: 0.5s all;
                position: relative;
                display:inline-block;
                cursor: pointer;
                text-align: center;
                &:hover{
                opacity: 0.8;
            }
        }
        .random{
            text-align:center;
            margin-top:30px;
            p{
                color:#ffffff;
                margin-top:30px;
               
                b{
                    text-decoration:underline;
                }
            }
        }
        .click_block{
            cursor: pointer;
            transition:all 0.5s;
            /* min-height:50px; */
            text-align: center;
            color:#ffffff;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            min-height: 600px;
            border:1px solid #ffffff;
            padding:15px;
            max-width: 1000px;
            margin: 0 auto;
            .logo{
                img{
                margin: 0 auto;
                width: 500px;
                margin-bottom: 20px;
                }
            }
        }
        .mute_control{
            display: flex;
            align-items: center;
            position: absolute;
            right: 20px;
            top: 20px;
            .mute_btn{
                &.active{
                    display: none;
                }
            }
            .mute{
                width:100%;
                max-width:2em;
                
            }
        }
    .mobile_show{
        display:none;
    }
    @media (max-width:991px){
        .title{
            padding-bottom:0.2em;
        }
    }

    @media (max-width:768px){
            .button_wrap{
                margin-left:10px;
                .btn_go{
                display:none;
                }
            }
            .mobile_show{
                display:block;
                text-align: center;
            }
            .pc_show{
                display:none
            }
        }
    .arrow{
        padding-top:2em;
        padding-bottom:2em;
        text-align:center;
        svg{
            max-width:30px;
            margin:0 auto;
        }
    }
    .result_block{
        margin-top:30px;
        position:relative;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        min-height: 600px;
        border:1px solid #ffffff;
        padding:15px;
        display: none;
        max-width: 1000px;
            margin: 0 auto;
        .logo{
            img{
            margin: 0 auto;
            width: 500px;
            margin-bottom: 20px;
            }
        }

        div.confirm_block,div.loading,div.completed,div.error{
            color:#ffffff;
            display: none;
        }
        div.confirm_block{
            button{
                transition: 0.5s all;
                &:hover{
                    background-color: #ffffff;
                    color:#000000;
                }
            }
        }
        .loading{
            text-align: center;
            width:100%;
            padding-top: 20px;
            img{
                margin: 0 auto;
            }
        }

            .confirm_block{
                display: flex;
                flex-wrap: wrap;
                h2{
                    width: 100%;
                    margin-bottom: 15px;
                }
                .btn_wrap{
                    width: 100%;
                    button{
                        min-width: 150px;
                        border: 1px solid #ffffff;
                        padding: 10px;
                        border-radius: 30px;
                        margin-left: 10px;
                        margin-right: 10px;
                        font-size: 25px;
                    }
                    @media (max-width:768px){
                        button{
                            min-width: 120px;
                            border: 1px solid #ffffff;
                            padding: 10px;
                            border-radius: 30px;
                            margin-left: 10px;
                            margin-right: 10px;
                            font-size: 18px;
                        }
                    }
                }

            }
            .completed{
                h2{
                    margin-bottom: 15px;
                    width: 100%;
                }
                a{
                        min-width: 300px;
                        border: 1px solid #ffffff;
                        padding: 10px;
                        border-radius: 30px;
                        margin-left: 10px;
                        margin-right: 10px;
                        font-size: 25px;
                        display: inline-block;
                    }
            }
            .error{
                h2{
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
        
    }
    @keyframes ldio-500h99ahr6e {
        0% { opacity: 1 }
        100% { opacity: 0 }
        }
        .ldio-500h99ahr6e{
            position: relative;
            top: -60px;
        }
        .ldio-500h99ahr6e div {
        left: 96.53px;
        top: 68.95px;
        position: absolute;
        animation: ldio-500h99ahr6e linear 1s infinite;
        background: #f4f4f4;
        width: 3.94px;
        height: 7.88px;
        border-radius: 0.7091999999999999px / 0.7091999999999999px;
        transform-origin: 1.97px 29.55px;
        }.ldio-500h99ahr6e div:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -0.9285714285714286s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(2) {
        transform: rotate(25.714285714285715deg);
        animation-delay: -0.8571428571428571s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(3) {
        transform: rotate(51.42857142857143deg);
        animation-delay: -0.7857142857142857s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(4) {
        transform: rotate(77.14285714285714deg);
        animation-delay: -0.7142857142857143s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(5) {
        transform: rotate(102.85714285714286deg);
        animation-delay: -0.6428571428571429s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(6) {
        transform: rotate(128.57142857142858deg);
        animation-delay: -0.5714285714285714s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(7) {
        transform: rotate(154.28571428571428deg);
        animation-delay: -0.5s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(8) {
        transform: rotate(180deg);
        animation-delay: -0.42857142857142855s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(9) {
        transform: rotate(205.71428571428572deg);
        animation-delay: -0.35714285714285715s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(10) {
        transform: rotate(231.42857142857142deg);
        animation-delay: -0.2857142857142857s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(11) {
        transform: rotate(257.14285714285717deg);
        animation-delay: -0.21428571428571427s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(12) {
        transform: rotate(282.85714285714283deg);
        animation-delay: -0.14285714285714285s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(13) {
        transform: rotate(308.57142857142856deg);
        animation-delay: -0.07142857142857142s;
        background: #f4f4f4;
        }.ldio-500h99ahr6e div:nth-child(14) {
        transform: rotate(334.2857142857143deg);
        animation-delay: 0s;
        background: #f4f4f4;
        }
        .loadingio-spinner-spinner-93u3dagbpgc {
        width: 197px;
        height: 197px;
        display: inline-block;
        overflow: hidden;
        background: none;
        }
        .ldio-500h99ahr6e {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
        }
        .ldio-500h99ahr6e div { box-sizing: content-box; }
`;



const Merge = () => {
    // 測試
    // merge & split:
    // KT1QfXkuuBrX8Ev3tgV1dfG6ftXbscxJWZJm
    // FA2:
    // KT1U6EHmNxJTkvaWJ4ThczG4FSDaHC21ssvi

    // 正式
    // merge & split:
    // KT1JWfpKDZMKet7CUXSvubvsFsm1vrLwcarK
    // FA2:
    // KT1AGCjqBPpdyenBdvi3YxmXWpKYp9JMuKAu

    const moduleFA2 = "KT1U6EHmNxJTkvaWJ4ThczG4FSDaHC21ssvi"
    const pixelboardFA2 = "KT1AFq5XorPduoYyWxs5gEyrFK6fVjJVbtCj"
    const mergeContract = "KT1JWfpKDZMKet7CUXSvubvsFsm1vrLwcarK"
    const [iframBlock] = useState(false);
    const { merge } = useContext(MyContext)

    const mergeControl = () => {
        const phase1ID = document.getElementById('window_select').value;
        const phase2ID = document.getElementById('building_select').value;
        const select3Data = Array.prototype.slice.call(document.querySelectorAll('.obj_select option:checked'),0);
        const phase4ID = document.getElementById('Atmosphere_select').value;
        const select5Data = Array.prototype.slice.call(document.querySelectorAll('.pxiel_board_select option:checked'),0)
        let phase3IDValues = Array.prototype.slice.call(document.querySelectorAll('.obj_select option:checked'),0).map(function(v,i,a) { 
            return v.value; 
        });
        let phase5IDValues = Array.prototype.slice.call(document.querySelectorAll('.pxiel_board_select option:checked'),0).map(function(v,i,a) { 
            return v.value; 
        });
        let phase3IDList = []
        if(phase3IDValues[0]==='' || phase3IDValues[1]==='' || phase3IDValues[2]===''){
            phase3IDList = []
        }else{
            phase3IDList = phase3IDValues
        }
        let phase5IDList = []
        if(phase5IDValues[0]==='' || phase5IDValues[1]==='' || phase5IDValues[2]==='' || phase5IDValues[3]==='' || phase5IDValues[4]==='' || phase5IDValues[5]===''|| phase5IDValues[6]===''|| phase5IDValues[7]===''|| phase5IDValues[8]===''){
            phase5IDList = []
        }else{
            phase5IDList = phase5IDValues
        }
       
        let err = '';
        select3Data.forEach((el, i) => {
            // console.log(el.text);
            select3Data.forEach((element, index) => {
                    if (i === index) return null;
                    if (el.value === '') {
                        err = 'Please Select Object field\n'
                    }
                    if (el.value === element.value) {
                        err = ''+el.text+'\nRepeat selection\nPlease reselect\n'
                    }
                });
        });
        select5Data.forEach((el, i) => {
            // console.log(el.text);
            select5Data.forEach((element, index) => {
                    if (i === index) return null;
                    if (el.value === '') {
                        err = 'Please Select PixelBoard field\n'
                    }
                    if (el.value === element.value) {
                        err = 'BlockTrain-PixelBoard-'+el.text+'\nRepeat selection\nPlease reselect\n'
                    }
                });
        });
       
        if(phase1ID === ''){
            err += 'Please select window field\n'
        }
        if(phase2ID === ''){
            err += 'Please select building field\n'
        }
        if (err !== '') {
            alert(err);
            return;
        }

        


        
        

        

        const loading = () => {
            document.getElementById('result_block').style.display = "flex";
            document.getElementById('result_loading').style.display = "block";
            document.getElementById('click_block').style.display = "none";
            document.getElementById('confirm_block').style.display = "none";

            console.log('loading');
        }
        const completed = () => {
            document.getElementById('result_completed').style.display = "block";
            document.getElementById('result_loading').style.display = "none";
            console.log('completed');
        }
        const getError = () => {
            document.getElementById('result_error').style.display = "block";
            document.getElementById('result_loading').style.display = "none";
            console.log('getError');
        }

        console.log('phase1ID:',phase1ID);
        console.log('phase2ID:',phase2ID);
        console.log('phase3IDList:',phase3IDList);
        console.log('phase4ID:',phase4ID);
        console.log('phase5IDList:',phase5IDList);
        merge(moduleFA2, pixelboardFA2, mergeContract, phase1ID, phase2ID,phase3IDList,phase4ID,phase5IDList,loading,completed,getError)
    }
    const noControl = () => {
        document.getElementById('result_block').style.display = "none";
        document.getElementById('click_block').style.display = "flex";
       
    }
    const goToMerge = () => {
        const phase1ID = document.getElementById('window_select').value;
        const phase2ID = document.getElementById('building_select').value;
        const select3Data = Array.prototype.slice.call(document.querySelectorAll('.obj_select option:checked'),0);
        const select5Data = Array.prototype.slice.call(document.querySelectorAll('.pxiel_board_select option:checked'),0)
        let err = '';
        select3Data.forEach((el, i) => {
            // console.log(el.text);
            select3Data.forEach((element, index) => {
                    if (i === index) return null;
                    if (el.value === '') {
                        err = 'Please Select Object field\n'
                    }
                    if (el.value === element.value) {
                        err = ''+el.text+'\nRepeat selection\nPlease reselect\n'
                    }
                });
        });
        select5Data.forEach((el, i) => {
            // console.log(el.text);
            select5Data.forEach((element, index) => {
                    if (i === index) return null;
                    if (el.value === '') {
                        err = 'Please Select PixelBoard field\n'
                    }
                    if (el.value === element.value) {
                        err = 'BlockTrain-PixelBoard-'+el.text+'\nRepeat selection\nPlease reselect\n'
                    }
                });
        });
       
        if(phase1ID === ''){
            err += 'Please select window field\n'
        }
        if(phase2ID === ''){
            err += 'Please select building field\n'
        }
        if (err !== '') {
            alert(err);
            return;
        }

        document.getElementById('result_block').style.display = "flex";
        document.getElementById('confirm_block').style.display = "block";
        document.getElementById('click_block').style.display = "none";
       
    }


    return(
        <MergeStyle>
            <Container>
            {/* <SplitControl /> */}
                    <Row className="control_top" alignItems={"center"}>
                        <Col col={12} md={6}>
                                <h1 className="title">Merge my Tokens</h1>
                                <p className="desc">
                                        Please sync with your wallet to merge.   
                                        <Link to="/how_to_merge">How to Merge.</Link>            
                                </p>
                        </Col>
                        <Col className="right_block" col={12} md={6}>
                            <div className="button_wrap">
                                <Link className="btn_view" to="/collections"  >View Collections</Link>
                                <Link className="btn_go pc_show"   to="#result_block_a"  onClick={goToMerge}>{'Merge >>'}</Link>
                                {/* <Link className="btn_go"   onClick={splitControl}>{'split >>'}</Link> */}
                            </div>

                        </Col>
                    </Row>
                    <ControlMain />
                    <div className="arrow" id="result_block_a">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.414" height="47.414" viewBox="0 0 17.414 47.414">
                        <path id="arrow1" data-name="arrow (1)" d="M1,26H44.586l-6.293,6.293,1.414,1.414L48.414,25l-8.707-8.707-1.414,1.414L44.586,24H1Z" transform="translate(33.707 -1) rotate(90)" fill="#fff"/>
                    </svg>
                    </div>
                    <div className="mobile_show">
                        <Link className="btn_go"   to="#result_block_b"  onClick={goToMerge}>{'Merge >>'}</Link>
                        <div className="arrow " id="result_block_b">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.414" height="47.414" viewBox="0 0 17.414 47.414">
                                <path id="arrow1" data-name="arrow (1)" d="M1,26H44.586l-6.293,6.293,1.414,1.414L48.414,25l-8.707-8.707-1.414,1.414L44.586,24H1Z" transform="translate(33.707 -1) rotate(90)" fill="#fff"/>
                            </svg>
                        </div>
                        
                    </div>
                 <div id="click_block" onClick={goToMerge} className="click_block" style={{display: iframBlock ? 'none' : 'flex' }}>
                     <div className="wrap">
                        <div className="logo">
                            <img src={process.env.PUBLIC_URL + '/images/nft-logo.svg'} alt="" />
                        </div>
                        <h2>Click to Merge</h2>
                     </div>

                 </div>
                 
                 <div className="result_block" id="result_block">
                     
                     <div className="wrap">
                        <div className="logo">
                            <img src={process.env.PUBLIC_URL + '/images/nft-logo.svg'} alt="" />
                        </div>
                        <div id="confirm_block" className="confirm_block">
                            <h2>Ready to MERGE your tokens?</h2>
                            <div className="btn_wrap">
                                <button onClick={mergeControl}>Yes</button>
                                <button onClick={noControl}>No</button>
                            </div>
                        </div>
                        
                        <div id="result_loading" className="loading">
                            <h2>Processing...</h2>
                            <div className="loadingio-spinner-spinner-93u3dagbpgc"><div className="ldio-500h99ahr6e">
                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div></div>
                        </div>
                        <div id="result_completed" className="completed">
                            <h2>Merge Completed.<br />Please see My collections.</h2>
                            <Link  to="/collections" >{'My Collections'}</Link>
                        </div>
                        <div id="result_error" className="error">
                            <h2>An error has occurred</h2>
                            
                        </div>
                    </div>
 
                 </div>

                 
            </Container>
        </MergeStyle>



    );
}

export default Merge;

