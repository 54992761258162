import React,{  useState,useEffect } from "react";
import styled from 'styled-components';
import axios from 'axios';
import { useLazyQuery, gql } from "@apollo/client";

const Button = styled.button`

    background-color: #ffffff;
    text-align: center;
    padding: 0px 0;
    width: 100%;
    border-radius: 25px;
    margin-bottom: 10px;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
    span{
        font-size: 30px;
        padding-bottom: 5px;
        display: inline-block;
    }
    &:hover{
        opacity: 0.8;
        -webkit-transition: 0.5s all;
        transition: 0.5s all;
    }
`;
const RemoveButton = styled.button`
    border-radius: 25px;
    border: 1px solid #000000;
    position: absolute;
    right: 10px;
    top: 7px;
    height: 30px;
    width: 30px;
    background-color: #ffffff;
    color: #000000;
`;
const RemoveBlcok = styled.span`
    text-indent: -9999px;
    position: absolute;
    width: 100%;
`;


var ls = require('local-storage')


export const WindowInput = () => {

    const [ inputVal, setInputVal] = useState('');
    const [ image, setImage] = useState(process.env.PUBLIC_URL + '/images/cover1.jpg');
      
   const handleChange = (event) => {
        setInputVal(event.target.value)
    }
    const WINDOW_QUERY = gql`
        query getGentk($slug: String) @api(name: "fxhash"){
            objkt(slug: $slug) {
            metadata
            }
        }
    `;

    const variables = {
        "slug": "blocktrain-window-"+inputVal
    } ;

    const [
        handleBlur, 
      ] = useLazyQuery(WINDOW_QUERY,
        { 
            variables: variables,
            onCompleted: someData => {
                if(someData !== null){
                    const image = someData.objkt.metadata.displayUri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")
                    const WindowData = someData.objkt.metadata.attributes;
                    // console.log(WindowData)
  
                    setImage(image)
                    ls.set('WindowData', WindowData)
                }


            }
        });
   
    return (
        <div className="card">
            <h3 className="title"><a href="https://www.fxhash.xyz/generative/20320" rel="noreferrer" target="_blank">Window<span className="right_arrow">{" >"}</span></a></h3>
            <div className="image">
                <img src={image} alt="" />
            </div>
            <div className="control">
                <label htmlFor="window">#</label>
                <input onBlur={() => handleBlur()} onChange={handleChange} id="window" value={inputVal} type="number"  placeholder="1-512 Enter NFT No." />
            </div>
            {/* <small>Minting opens on fxhash Oct 14</small> */}
        </div>
      );
}

export const BuildingInput = () => {
    const [ inputVal, setInputVal] = useState('');
    const [ image, setImage] = useState(process.env.PUBLIC_URL + '/images/cover2.jpg');
      
   const handleChange = (event) => {
        setInputVal(event.target.value)
    }
    const BUILDING_QUERY = gql`
        query getGentk($slug: String) @api(name: "fxhash"){
            objkt(slug: $slug) {
            metadata
            }
        }
    `;

    const variables = {
        "slug": "blocktrain-building-"+inputVal
    } ;

    const [
        handleBlur, 
      ] = useLazyQuery(BUILDING_QUERY,
        { 
            variables: variables,
            onCompleted: someData => {
                if(someData !== null){
                    const image = someData.objkt.metadata.displayUri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")
                    const BuildingData = someData.objkt.metadata.attributes;
                    // console.log(BuildingData)
  
                    setImage(image)
                    ls.set('BuildingData', BuildingData)
                }


            }
        });
   
   
    return (
        <div className="card">
            <h3 className="title"><a href="https://www.fxhash.xyz/generative/20538" rel="noreferrer" target="_blank">Building<span className="right_arrow">{" >"}</span></a></h3>
            <div className="image">
                <img src={image} alt="" />
            </div>
            <div className="control">
                <label htmlFor="Building">#</label>
                <input onBlur={() => handleBlur()} onChange={handleChange} id="Building" value={inputVal} type="number"  placeholder="1-512 Enter NFT No." />
            </div>
            {/* <small>Minting opens on fxhash Oct 21</small> */}
        </div>
      );
}

export const ObjectInput = () => {

    const initialState = [
        {image: process.env.PUBLIC_URL + '/images/cover3.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover3.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover3.jpg'}
        
    ];
    const initialValue = [
        {value: ''},
        {value: ''},
        {value: ''}
        
    ];
    const initialValData = [
        {data: null},
        {data: null},
        {data: null}
        
    ];
    const [inputVal, setInputVal] = useState(initialValue);
    const [number, setNumber] = useState(0);
    const [image, setImage] = useState(initialState);
    const [valData, setValData] = useState(initialValData);
    const [Rows, setRows] = useState(0);
    const [state, setState] = useState(true);




    const OBJECT_QUERY = gql`
    query getGentk($slug: String) @api(name: "fxhash"){
        objkt(slug: $slug) {
        metadata
        }
    }
`;

    const handleChange = ({ target }) => {
        let TargetId = target.id.replace('objectId','');
        setNumber(TargetId)
        setInputVal({
          ...inputVal,
          [number]: { value: target.value}
        })
    }
    const variables = {
        "slug": "blocktrain-object-"+inputVal[number].value
    } ;
    const [
        handleBlur,
    ] = useLazyQuery(OBJECT_QUERY,
        { 
            variables: variables,
            onCompleted: someData => {
                if(someData !== null){
                        const Data = someData.objkt.metadata.attributes[0].value;
                        const displayUri = someData.objkt.metadata.displayUri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")
                        // console.log(Data[0].value);
                        // console.log(number);
                        setValData({
                            ...valData,
                            [number]: { data: Data}
                        })
                        setImage({
                            ...image,
                            [number]: { image: displayUri}
                        })

                      
                    // ls.set('BuildingData', BuildingData)
                }
            }
        });

    ls.set('ObjData', valData)

    const addCard = () => {
        setRows(Rows+1);
    };
    const removeCard = () => {
        setRows(Rows-1);
        if(Rows===1){
            setValData({
                ...valData,
                [Rows]: { data: null}
            })
        }
        if(Rows===2){
            setValData({
                ...valData,
                [Rows]: { data: null}
            })
        }
    }
    const closeControl = () => {
        setState(true)
        
        for (let index = 0; index < 3; index++) {
            valData[index].data = null
        }
        inputVal[0].values =''
        setRows(0);
    }
    const openControl = () => {
        setState(false)
        
    }
    return (
        <div>
            <div className="card">
                    
                    <h3 className="title"><a href="https://www.fxhash.xyz/generative/slug/blocktrain-object" rel="noreferrer" target="_blank">Object<span className="right_arrow">{" >"}</span></a></h3>
                    <div className="wrap">
                        {!state && <span onClick={closeControl} className="close">X</span>}
                        <div  className="image">
                            <img id="objectImg0" src={image[0].image} alt="" />
                        </div>
                        <div className="control">
                            <label htmlFor="objectId0">#</label>
                            <input onBlur={handleBlur} id="objectId0" onChange={handleChange} value={inputVal[0].values} type="number"  placeholder="1-512 Enter NFT No." />
                        </div>
                        {state &&<div className="mask">
                            <span onClick={openControl}>✔</span>
                        </div>}
                    </div>
                    {/* <small>Minting opens on fxhash Oct 28</small> */}
            </div>
            {!state && Array.from({ length: Rows }, (_, index) => (
                    <div id={"object_card" + parseInt(index+1)} className="card" key={index}>
                       {Rows === index+1 && <RemoveButton onClick={removeCard}>X</RemoveButton>}
                        <div className="image">
                            <img id={"objectImg" + parseInt(index+1)} src={image[index+1].image} alt="" />
                        </div>
                        <div className="control">
                            <label htmlFor={"objectId" + parseInt(index+1)}>#</label>
                            <input onBlur={handleBlur} onChange={handleChange}  id={"objectId" + parseInt(index+1)} value={inputVal[index+1].values} type="number"  placeholder="1-512 Enter NFT No." />
                            
                        </div>
                        {/* <small>Minting opens on fxhash Oct 28</small> */}
                    </div>
                ))}
               

            {/* {Rows>=1 && <RemoveButton onClick={removeCard}><span>X</span></RemoveButton>} */}
           {!state &&  <div>
                {Rows<2 && <Button onClick={addCard}><span>+</span></Button>}
            </div>}
            
            
        </div>
       
      );
}

export const AtmosphereInput = () => {
    const [ inputVal, setInputVal] = useState('');
    const [ image, setImage] = useState(process.env.PUBLIC_URL + '/images/cover4.jpg');
    const [ state, setState] = useState(true);
    
    // const AtmosphereInit = [{"name":"Weather","value":"NULL"},{"name":"Button_REGENERATE","value":false},{"name":"Button_STOP","value":false},{"name":"Button_SPRINT","value":false},{"name":"Button_JUMP","value":false},{"name":"Button_LIGHT","value":false},{"name":"Button_SUBMERGE","value":false},{"name":"Button_SPRING","value":false},{"name":"Button_DARK","value":false}]
    // ls.set('Atmosphere', AtmosphereInit)
   const handleChange = (event) => {
        setInputVal(event.target.value)
    }
    const BUILDING_QUERY = gql`
        query getGentk($slug: String) @api(name: "fxhash"){
            objkt(slug: $slug) {
            metadata
            }
        }
    `;

    const variables = {
        "slug": "blocktrain-atmosphere-"+inputVal
    } ;

    const [
        handleBlur, 
      ] = useLazyQuery(BUILDING_QUERY,
        { 
            variables: variables,
            onCompleted: someData => {

                // console.log(someData);
                if(someData !== null){
                    const image = someData.objkt.metadata.displayUri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")
                    const Atmosphere = someData.objkt.metadata.attributes;
                    // console.log(Atmosphere)
                    ls.set('Atmosphere', Atmosphere)
                    setImage(image)
                    
                }


            }
        });

    const closeControl = () => {
        setState(true)
    }
    const openControl = () => {
        setState(false)
    }

    return (
        <div className="card">
           
            <h3 className="title"><a href="https://www.fxhash.xyz/generative/20941" rel="noreferrer" target="_blank">Atmosphere<span className="right_arrow">{" >"}</span></a></h3>
            <div className="wrap">
                {!state && <span onClick={closeControl} className="close">X</span>}
                <div className="image">
                    <img src={image} alt="" />
                </div>
                <div className="control">
                    <label htmlFor="Atmosphere">#</label>
                    <input onBlur={() => handleBlur()} onChange={handleChange} id="Atmosphere" value={inputVal} type="number"  placeholder="1-512 Enter NFT No." />
                </div>
                {state &&<div className="mask">
                    <span onClick={openControl}>✔</span>
                </div>}
            </div>

            {/* <small>Minting opens on fxhash Nov 4</small> */}
        </div>
      );
}
export const PixelBoardInput = () => {
    var ls = require('local-storage')
   
    
    const PixelBoardApi = "https://api.akaswap.com/v2/curations/BlockTrainPixelBoard"
    const PixelBoardData = []
    const PixelBoardTxt = []
    const [Rows, setRows] = useState(0);
    const [trainList,setTrainList] = useState([]);
    const [PixelBoardTxtDataStore,setPixelBoardTxtDataStore] = useState([]);
    const [PixelBoardDataStore,setPixelBoardDataStore] = useState([]);
    const [ state, setState] = useState(true);
    ls.set('PixelBoardTxt',PixelBoardTxtDataStore)
    ls.set('PixelBoardData',PixelBoardDataStore)
    const initialValue = [
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''}
        
    ];
    const initialState = [
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        
    ];
    const [selectedOption, setSelectedOption] = useState(initialValue);
    const [image ,setImage] = useState(initialState);

    const handleChange = (event) => {
        const PixelBoardData =  ls.get('PixelBoardData')
        let imageChange = ''
        PixelBoardData.forEach(function(item, i) {
           
            if(parseInt(event.target.value) === parseInt(item.tokenId)){
                imageChange = item.data.displayUri
              
            }
            if(event.target.value === ''){
                imageChange = process.env.PUBLIC_URL + '/images/cover5.jpg'
              
            }

        });
        let TargetId = event.target.id.replace('Pixel_Board_','');
        setSelectedOption({
            ...selectedOption,
            [TargetId]: {value:event.target.value}
        })
        setImage({
            ...image,
            [TargetId]: { image: imageChange}
        })
    }

    const addCard = () => {
        setRows(Rows+1);
    };
    const removeCard = (event) => {
        setRows(Rows-1);
         let TargetId = event.target.id.replace('Pixel_Board_remove','');
        //  console.log(TargetId);
          setSelectedOption({
              ...selectedOption,
              [TargetId]: {value:''}
          })
          setImage({
              ...image,
              [TargetId]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
          })

    };
    const closeControl = () => {
        setState(true)
        
        setSelectedOption({
            ...selectedOption,
            [0]: {value: ''},
            [1]: {value: ''},
            [2]: {value: ''},
            [3]: {value: ''},
            [4]: {value: ''},
            [5]: {value: ''},
            [6]: {value: ''},
            [7]: {value: ''},
            [8]: {value: ''},
        })
        setImage({
            ...image,
            [0]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [1]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [2]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [3]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [4]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [5]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [6]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [7]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
            [8]: {image: process.env.PUBLIC_URL + '/images/cover5.jpg'},
        })
        setRows(0);
    }
    const openControl = () => {
        setState(false)
        
    }

    useEffect(() => {
        
        axios.get(PixelBoardApi)
        .then((response) => {
            setTimeout(() => {
                setTrainList(response.data[0])
               
              }, 2000);
            const data = response.data[0]

            data.forEach(function(item, i) {
                // console.log(item);

                let dataLink = item.artifactUri.replace("ipfs://", "https://assets.akaswap.com/ipfs/")
                let px0 = dataLink+'/data/px0.txt'
                let px1 = dataLink+'/data/px1.txt'
                let px2 = dataLink+'/data/px2.txt'
            
                async function getPx(pxUrl,tokenId) {
                    try {
                      const response = await axios.get(pxUrl);
                            PixelBoardTxt.push({
                                'tokenId': tokenId,
                                'data': response.data
            
                            }) 
                    //   console.log(response);
                    } catch (error) {
                      console.error(error);
                    }
                  }
                  getPx(px0,item.tokenId)
                  getPx(px1,item.tokenId)
                  getPx(px2,item.tokenId)


                PixelBoardData.push({
                    'tokenId': item.tokenId,
                    'data':{
                        'name': item.name,
                        'artifactUri': item.artifactUri.replace("ipfs://", "https://assets.akaswap.com/ipfs/"),
                        'displayUri': item.displayUri.replace("ipfs://", "https://assets.akaswap.com/ipfs/"),
                    }
                    
                }) 

              });
            setPixelBoardTxtDataStore(PixelBoardTxt)
            setPixelBoardDataStore(PixelBoardData)
           
            
        });
      },[PixelBoardApi])

    return (
        <div>
            <div className="card">
                    
                    <h3 className="title">
                        <a href="https://akaswap.com/curation/BlockTrainPixelBoard" rel="noreferrer" target="_blank">
                            PixelBoard<span className="right_arrow">{" >"}</span>
                        </a>
                    </h3>
                    <div className="wrap">
                    {!state && <span onClick={closeControl} className="close">X</span>}
                        <div className="image">
                            <img src={image[0].image} alt="" />
                        </div>
                        <div className="control">
                            <label htmlFor="Pixel_Board_0">#</label>
                            
                            <select className="Pixel_Board_select"  id="Pixel_Board_0"
                                value={selectedOption[0].value}
                                onChange={handleChange}
                            >
                                        <option value="">
                                            Select
                                        </option>
                                    {trainList.map((nftItem) => (
                                        <option value={nftItem.tokenId} key={nftItem.tokenId}>
                                            {nftItem.tokenId}
                                        </option>
                                    
                                    ))}
                            </select>
                        </div>
                        {state &&<div className="mask">
                            <span onClick={openControl}>✔</span>
                        </div>}
                    </div>
                    {/* <small>Minting not started</small> */}
            </div>

            {!state &&Array.from({ length: Rows }, (_, index) => (
                <div className="card" key={index}>
                  {Rows === index+1 && <RemoveButton id={"Pixel_Board_remove"+ parseInt(index+1)} onClick={removeCard}>X</RemoveButton>}
                    <div className="image">
                        <img src={image[index+1].image} alt="" />
                    </div>
                    <div className="control">
                        <label htmlFor={"Pixel_Board_" + parseInt(index+1)}>#</label>
                        <select className="Pixel_Board_select" id={"Pixel_Board_" + parseInt(index+1)}
                            value={selectedOption[parseInt(index+1)].value}
                            onChange={handleChange}
                        >
                                    <option value="">
                                        Select
                                    </option>
                                {trainList.map((nftItem) => (
                                    <option value={nftItem.tokenId} key={nftItem.tokenId}>
                                        {nftItem.tokenId}
                                    </option>
                                
                                ))}
                        </select>
                        {/* <input id={"Pixel_Board_" + index} type="text" readOnly placeholder="Random" /> */}
                        
                    </div>
                    {/* <small>Minting not started</small> */}
                </div>
            ))}
            {!state &&  <div>
                {Rows<8 && <Button  onClick={addCard}><span>+</span></Button>}
            </div>}
            
            
        </div>
       
      );
}


