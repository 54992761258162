import React from "react";
import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const HowToMergeStyle = styled.section`
    background-color: #1A1613;
    .back{
        color:#ffffff;
        text-decoration: underline;
        font-size: 1.5em;
        position: sticky;
        transform: translate(-100px, 30px);
        top: 100px;
    }
    @media(max-width:767px){
        .back{
            position: relative;
            transform: translate(0px, 0px);
            top: 0;
        }
    }
    .image_item{
        margin-bottom: 3em;
        max-width:1200px;
        margin:30px auto;
        
        img{
            border-radius: 25px;
            margin:0 auto;
        }
    }
    .btn_group{
        text-align: center;
        margin-bottom:15px;
        
        a{
            /* margin-right: 15px; */
            color:#000;
            box-shadow:0 2px 14px -4px rgb(0 0 0 / 67%);
            border:1px solid #c8ff00;
            padding:10px 20px;
            display: inline-block;
            background-color:#c8ff00;
            min-width: 200px;
            text-align: center;
            transition: 0.5s all;
            font-weight: bold;
            &:hover{
                opacity:0.8;
            }
        }
    }
`;




const HowToMergeSection = () => {


    return(
        <HowToMergeStyle>
                
                <Container>
                    
                    <Row>
                        <Link className="back" to="/">{"< back"}</Link>
                        <Col col={12} lg={12} >
                            <div className="image_group">
                                <div className="image_item">
                                    <img src={process.env.PUBLIC_URL + '/images/how/how1.jpg'} alt="" />
                                </div>
                                <div className="image_item">
                                    <img src={process.env.PUBLIC_URL + '/images/how/how2.jpg'} alt="" />
                                </div>
                                <div className="image_item">
                                    <img src={process.env.PUBLIC_URL + '/images/how/how3.png'} alt="" />
                                </div>
                                <div className="image_item">
                                    <img src={process.env.PUBLIC_URL + '/images/how/how4.jpg'} alt="" />
                                </div>
                                
                            </div>
                            <div className="btn_group">
                                <Link to="/merge">{'Merge >>'}</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
        </HowToMergeStyle>
    );
}


export default HowToMergeSection;
