import React, { useEffect } from 'react'
import {  Routes, Route, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4'
import Home from "../pages/Home";
import HowToMerge from "../pages/HowToMerge";
import MyCollections from "../pages/MyCollections";
import PixelBoard from "../pages/PixelBoard";
import Merge from "../pages/Merge";

import { AnimatePresence } from "framer-motion"

ReactGA.initialize("G-X8L4LB5572");

ReactGA.send("pageview");

const AnimatedRoutes = () => {
  const location = useLocation();
  const { pathname, hash, key } = useLocation();


  useEffect(() => {
    
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({behavior: 'smooth'});
        }
      }, 500);
    }
  }, [pathname, hash, key]); // do this on route change

  
  return (
    <AnimatePresence>
       {/* <ScrollToTop /> */}
        <Routes location={location} key={location.pathname}>
            <Route path="/" exact element={ <Home/> } />
            <Route path="/how_to_merge" element={ <HowToMerge /> } />
            <Route path="/collections" element={ <MyCollections /> } />
            <Route path="/pixel_board" element={ <PixelBoard /> } />
            <Route path="/merge" element={ <Merge /> } />
        </Routes>
    </AnimatePresence>

  )
}

export default AnimatedRoutes