import React from "react";
import styled from 'styled-components';

const Footerbottom = styled.div`
display:flex;
color:#FFFFFF;
margin-top: 2em;
@media(max-width:768px){
    justify-content:center;
    margin-top: 1em;
}
`;

const Copyright = () => {
    return(
        <Footerbottom>
            <span>© 2022 BlockTrain</span>
        </Footerbottom>
    );
}

export default Copyright;