import React from "react";
// import { SyncButton } from '../syncButton';
import Copyright from './Copyright';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const Stylefooter = styled.footer`
background-color:#1A1613;
padding:5em 2em 2em;

.footerLogo{
  margin-right:3em;
  img{
    width:100%;
    max-width:18em;
    min-width: 15em;
  }
}

.footerMenu{
  border-left: 2px solid #fff;
  padding-left:2em;
  //margin-left:2em;
  ul{
    li{
      color:#fff;
      margin-bottom:0.5em;
      text-align: left;
      &:last-child{
        margin-bottom:0;
      }
    }
  }
}

@media(max-width:991px){
  padding:2em 1em;
  .footerLogo{
    margin-right:0;
    margin-bottom:2em;
    img{
      //max-width: 70%;
    }
  }
}

`;

const Footerright = styled.div`
display:flex;
flex-flow: column wrap;
align-items:center;
padding:2em 0 1em;
@media(max-width:991px){
  flex-flow:row nowrap;
  justify-content:center;

  .socialIcon{
    padding:0!important;
  }

  .syncButton{
    margin-left:2em;
  }
}

.socialIcon{
  display:flex;
  justify-content:center;
  padding:0 0 1em;
  img{
    margin:0 0.5em;
  }

}
`;

const Footer = () => {
    return(
    <Stylefooter>
      <Container>
        <Row>
        <Col col={12} lg={4}>
          <div className="footerLogo">
            <Link to="/">
              <img src={process.env.PUBLIC_URL + '/images/nft-logo.svg'} alt=""  />
            </Link>
          </div>
        </Col>
        <Col col={12} lg={6} className="footerMenu">
          <ul>
            <li><Link to="/collections">My Collections</Link></li>
            <li><Link to="/#about">About</Link></li>
            <li><Link to="/#team">Team</Link></li>
            <li><Link to="/how_to_merge">How to Merge</Link></li>
            <li><Link to="/#faq">FAQ</Link></li>
          </ul>
        </Col>
        <Col col={12} lg={2}>
          <Footerright>
            <div className="socialIcon">
              <a href="https://twitter.com/blocktrainart" rel="noreferrer" target="_blank">
                <img src={process.env.PUBLIC_URL + '/images/twitter.svg'} alt="" />
              </a>
              <a href="https://discord.gg/Et9MruF8XJ" rel="noreferrer" target="_blank">
                <img src={process.env.PUBLIC_URL + '/images/dc.svg'} alt=""  />
              </a>
            </div>
            {/* <div className="syncButton">
              <SyncButton />
            </div> */}
          </Footerright>
        </Col>
        </Row>
        <Copyright />
        </Container>
    </Stylefooter>

    );
}

export default Footer;