import React, {useEffect, useState} from 'react';

//import Hamburger from './Hamburger';
import Nav from './Nav';
//import'./Header.css';
import { SyncButton } from '../syncButton';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";

// ES6/ES2015
//import configData from '../../galleryConfing.json';

const Styleheader = styled.header`
position:relative;
top:0;
left:0;
width: 100%;
background-color:#1A1613;
padding:2em;
z-index: 20;
&.fixed{
    position: fixed;
    width: 100%;
}
@media (max-width: 991px){
    padding:1em;
}

.inner{
    display:flex;
    justify-content:space-between;
    align-items:center;
}
`;

const Headerright = styled.div`
display:flex;
.rightBlock{
    display:flex;
    align-items:center;
    a{
        color:#fff;
        white-space:pre;
        margin:0 2em 0 0;
        padding-left:1.5em;
        /* border-left:1px solid #707070;  */
    }
    .mute_block{
        display: flex;
        align-items: center;
        .mute_btn{
            &.active{
                display: none;
            }
        }
        .mute{
            width:100%;
            max-width:2em;
            margin-right:1.5em;
        }
    }

    @media(max-width:768px){
        .goCollections{
            display:none;
        }
        .mute_block{
            .mute{
                margin-right: 1em;
            }
        }
    }
}
`;


const Header = () => {

    // const [mutedVideo, setMutedVideo] = useState(true);
    // const muteOpen = () =>{
    //     let vid = document.getElementById("KV_Video");
    //     setMutedVideo(true);
    //     vid.muted = true;
    // }
    // const muteClose = () =>{
    //     let vid = document.getElementById("KV_Video");
    //     setMutedVideo(false);
    //     vid.muted = false;
    // }

    const [scroll, setScroll] = useState(false);
    // const pageContent = document.getElementById('page_content');
    window.addEventListener("scroll", () => {
        if(window.scrollY > 0){
            setScroll(true);
            document.getElementById('page_content').style.paddingTop = '60px'
        }else{
            setScroll(false);
            document.getElementById('page_content').style.paddingTop = '0px'
        }
        
    });
    useEffect(() => {

    }, []);

    
    

    return(
    <Styleheader id="header" className={scroll ? "header fixed" : "header"}>
            <div className="inner">
                <Nav />
                <Headerright>
                    <div className="rightBlock">
                        {/* <div className="mute_block">
                        <button onClick={muteClose} className={"mute_btn " + ( !mutedVideo ? "active" : "")}>
                            <img className="mute" src={process.env.PUBLIC_URL + '/images/mute.png'} alt="" />
                        </button>
                        <button onClick={muteOpen} className={"mute_btn " + (mutedVideo ? "active" : "")}>
                            <img className="mute" src={process.env.PUBLIC_URL + '/images/unmute.png'} alt="" />
                        </button>
                        </div> */}
                        <NavLink to="/collections" className="goCollections">
                            My Collections
                        </NavLink>
                    </div>
                    <SyncButton />
                </Headerright>
            </div>
    </Styleheader>
    );
}

export default Header;