import React from "react";
import {LinkButton} from '../../linkButton';

import styled from 'styled-components';

const KVsection = styled.section`
    background-color: #1A1613;
    margin-top: -1px;
        
        .video_container{
            position: relative;
            width: 70%;
            height: 100vh;
            margin: 0 auto;
            overflow: hidden;
            z-index: 0;
            min-height: 25rem;
            padding:5em 0 0;
            //margin-top: -8em;
            video{
                position: absolute;
                top: 50%;
                left: 50%;
                height: auto;
                width: auto;
                transform: translateX(-50%) translateY(-50%);
                max-width: initial;
            }
            .vid_inner{
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%, -50%);
                width: 100%;
                z-index: 1;
                .vid_logo{
                    width: 100%;
                    max-width: 30em;
                    margin: 0 auto;
                }
                h1{
                    color:#fff;
                    font-size: 2rem;
                    letter-spacing: 6px;
                    padding: 1em 0;
                    text-align: center;
                }
                .share{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        margin: 0 0.5em;
                    }
                    img,svg{
                        width: 100%;
                        //padding:0 0.5em;
                    } 
                    .aka{
                        border: 2px solid #ffffff;
                        border-radius: 30px;
                        width: 54px;
                        height: 54px;
                        padding-top: 12px;
                    }
                }
                .go_btn{
                    text-align: center;
                    margin-top: 20px;
                }
            }
        }

        .kv_content{
            position: relative;
            text-align: center;
            color:#fff;
            padding:2em;
            margin-top: -2em;
            .mb{
                margin-bottom: 1em;
            }
            p{
                font-size: 1.125rem;
                text-align: center;
                padding:2em 0 1em;
            }
            .ver_line{
                background-color: #fff;
                height:70px;
                width:1px;
                margin:0 auto;
                position: relative;
                //&:after{
                    //content: '';
                    //position: absolute;
                    //height:20px;
                    //background-color: #fff;
                    //width:1px;
                    //top:3.5em;
                    //right:0;
                    //left:0;
                    //bottom: 0;
                //}
            }
        }

        @media screen and (max-width:991px) {
        .video_container{
            width:100%;
            height:50vh;
            video{
                //width:auto;
                //height:auto;
            }
            .vid_inner{
                .vid_logo{
                    max-width: 20em;
                }
                h1{
                    font-size: 1rem;
                    letter-spacing: 2px;
                }
                .share{
                    a{
                        margin:0 0.3em;
                    }
                    img,svg{
                        max-width: 50px;
                        padding:0;
                        margin:0 auto;
                    }
                    .aka{
                        border: 2px solid #ffffff;
                        border-radius: 30px;
                        width: 50px;
                        height: 50px;
                        padding-top: 12px;
                    }
                }
            }
        }
        .kv_content{
            margin-top: 0;
            padding:0 2em 0;
        }
    }
    
`;


const Keyvision = () => {
    return(
    <KVsection>
        <div className="video_container">
            <video 
            id="KV_Video" 
            src={process.env.PUBLIC_URL + '/assets/video/blocktrain1920.mp4'} 
            muted="muted"
            autoPlay="autoplay"
            playsInline
            loop="loop"
            />
            <div className="vid_inner">
                <img className="vid_logo" src={process.env.PUBLIC_URL + '/images/nft-logo.svg'} alt="" />
                <h1>Traveling Between Dream &amp; Reality</h1>
                <div className="share">
                    <a href="https://akaswap.com/metaverse?collections=blocktrain" className="aka" rel="noreferrer" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25.911" height="22.859" viewBox="0 0 25.911 22.859">
                            <path id="Path_20" data-name="Path 20" d="M57.311,73.23V96.088H52.959V77.533h-.04L36.75,96.088H31.4L51.961,73.23Z" transform="translate(-31.4 -73.23)" fill="#fff"/>
                        </svg>
                    </a>
                    <a href="https://www.fxhash.xyz/u/BlockTrain" rel="noreferrer" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                        <g id="Group_30" data-name="Group 30" transform="translate(-642 -569)">
                            <g id="Group_28" data-name="Group 28" transform="translate(642.114 569)">
                            <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(-0.114)" fill="none" stroke="#fff" strokeWidth="2">
                                <circle cx="27" cy="27" r="27" stroke="none"/>
                                <circle cx="27" cy="27" r="26" fill="none"/>
                            </g>
                            <g id="logo" transform="translate(-507.114 -50)">
                                <g id="Group_29" data-name="Group 29" transform="translate(442 -65)">
                                <path id="Path_4" data-name="Path 4" d="M85.638,130a14.372,14.372,0,0,1,2.98.276,11.816,11.816,0,0,1,2.311.7l-1.265,3a8.055,8.055,0,0,0-1.643-.436,9.821,9.821,0,0,0-1.672-.145,3.082,3.082,0,0,0-1.963.494,2.052,2.052,0,0,0-.581,1.643v2.065h5.03l-.509,3.185H83.807V152.06H79.242V140.776H76v-3.185h3.242v-2.239a5.2,5.2,0,0,1,.727-2.734,4.994,4.994,0,0,1,2.151-1.9A7.816,7.816,0,0,1,85.638,130Z" fill="#fff"/>
                                <path id="Path_5" data-name="Path 5" d="M257.707,219.228h-4.972l5.291-8.114-4.8-7.329h5.146l2.471,4.944,2.384-4.944h4.885l-4.536,7.155,5.321,8.289h-5.2l-3.053-5.773Z" transform="translate(-160.886 -67.167)" fill="#fff"/>
                                </g>
                            </g>
                            </g>
                        </g>
                        </svg>
                    </a>
                    <a href="https://twitter.com/blocktrainart" rel="noreferrer" target="_blank">
                        <img src={process.env.PUBLIC_URL + '/images/twitter.svg'} alt="" />
                    </a>
                    <a href="https://discord.gg/Et9MruF8XJ" rel="noreferrer" target="_blank">
                        <img src={process.env.PUBLIC_URL + '/images/dc.svg'} alt=""  />
                    </a>
                   
                </div>
                <div className="go_btn">
                    <LinkButton name="View  PixelBoard" link="https://akaswap.com/curation/BlockTrainPixelBoard" open="_blank" />
                </div>
            </div>
        </div>
        <div className="kv_content">
            <p>It’s a trip without a destination.<br/> However, you’re not alone this time. Ready for a ride?<br/> 5 phases of BlockTrain bring us a journey on Tezos.</p>
            <div className="ver_line"></div>
            
        </div>
    </KVsection>
    );
}

export default Keyvision;