import React from "react";

import styled from 'styled-components';

const Styletriangle = styled.div`
background: url('images/triangle.png') no-repeat;
background-size: 100% auto;
background-position: top 0% center;
padding:4em;
/* margin-top: 3em; */
margin-bottom: -1px;
background-color: #ffffff;
@media (max-width:768px) {
    padding: 2em;
}

`;

const Triangle = () => {
    return(
        <Styletriangle  />
    );
}
export default Triangle