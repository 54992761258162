import React,{useContext} from "react";
import { MyContext } from "../../context/MyContext";
import { useQuery, gql } from "@apollo/client";
import styled from 'styled-components';


const SectionStyle = styled.section`
    padding-top:20px;
    padding-bottom: 20px;
    a{
        color:#ffffff;
        text-decoration: underline;
        margin-bottom: 15px;
        display: inline-block;
    }
    img{
        border:1px solid #ffffff;
    }
    p{
        color:#ffffff;
    }
`;



const NoNftSection = () => {
    const context = useContext(MyContext)
    const wallet = context.address


        const TOKEN_QUERY = gql`
            query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt") {
                token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
            }, limit: $limit, offset: $offset) {
                    name
                    token_id
                    display_uri
                    thumbnail_uri
                }
            }
        `;
        const variables = {
            "GenTokenNameRegex": "^BlockTrain",
            "Holder": wallet,
            "limit": 500,
            "offset": 0
        };
            
        const { data, loading, error } = useQuery(TOKEN_QUERY,{
            variables: variables,
            // skip: wallet
        });

        if (loading) return (
            <div>
                        <p>loading....</p>
            </div>
        );;
        // if (error) return (
        //     <SectionStyle><p>You have no BlockTrain NFT now</p></SectionStyle>
        // );
      
    return(
        <>
             {context.acc?.address && <div>
                {data.token.length === 0 && <SectionStyle><p>You have no BlockTrain NFT now</p></SectionStyle>}
            </div>}
               
        </>
    );
}


export default NoNftSection;
