import React,{useContext,useEffect,useState} from "react";
import { MyContext } from "../../context/MyContext";
import { Row, Col } from 'styled-bootstrap-grid';
import { useQuery, gql } from "@apollo/client";
import axios from "axios";

export const PassSection = () => {  
    const context = useContext(MyContext)
    const wallet = context.address

        const TOKEN_QUERY = gql`
            query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt"){
                token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
            }, limit: $limit, offset: $offset) {
                    name
                    token_id
                    display_uri
                    thumbnail_uri
                }
            }
        `;
        const variables = {
            "GenTokenNameRegex": "^BlockTrain PASS #[0-9]+$",
            "Holder": wallet,
            "limit": 500,
            "offset": 0
        };
            
    
        const { data, loading, error } = useQuery(TOKEN_QUERY,{
            variables: variables,
            // skip: wallet
        });

        if (loading) return (
            <div>
                        <p>loading....</p>
            </div>
        );;
        if (error) return (
            <div>
                        {/* <p>Connect Wallet</p> */}
            </div>
        );

    return(
        <div>
                   {data.token.length > 0 && <p>Pass &#62;</p>}
                    <Row className="card_row">
                    {data.token.map((nftItem) => (
                        <Col className="card" col={6} lg={1} key={nftItem.token_id}>
                            <a rel="noreferrer" target="_blank" href={"https://www.fxhash.xyz/gentk/"+nftItem.token_id}>
                                <img src={nftItem.display_uri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")} alt="" />
                                {nftItem.name}
                            </a>
                        </Col>
                    ))}
                   </Row>
        </div>
    );
}

export const WindowSection = () => {

    
    const context = useContext(MyContext)
    const wallet = context.address


        const TOKEN_QUERY = gql`
            query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt"){
                token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
            }, limit: $limit, offset: $offset) {
                    name
                    token_id
                    display_uri
                    thumbnail_uri
                }
            }
        `;
        const variables = {
            "GenTokenNameRegex": "^BlockTrain Window #[0-9]+$",
            "Holder": wallet,
            "limit": 500,
            "offset": 0
        };
            
        const { data, loading, error } = useQuery(TOKEN_QUERY,{
            variables: variables,
            // skip: wallet
        });

        if (loading) return (
            <div>
                        <p>loading....</p>
            </div>
        );;
        if (error) return (
            <div>
                        {/* <p>Connect Wallet</p> */}
            </div>
        );

    return(
        <div>
                    
                    {data.token.length > 0 && <p>Window &#62;</p>}
                    <Row className="card_row">
                    {data.token.map((nftItem) => (
                        <Col className="card" col={6} md={6} lg={2} key={nftItem.token_id}>
                            <a  rel="noreferrer" target="_blank" href={"https://www.fxhash.xyz/gentk/"+nftItem.token_id}>
                                <img src={nftItem.display_uri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")} alt="" />
                                {nftItem.name}
                            </a>
                        </Col>
                    ))}
                   </Row>
        </div>
    );
}
export const BuildingSection = () => {

    
    const context = useContext(MyContext)
    const wallet = context.address


        const TOKEN_QUERY = gql`
            query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt"){
                token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
            }, limit: $limit, offset: $offset) {
                    name
                    token_id
                    display_uri
                    thumbnail_uri
                }
            }
        `;
        const variables = {
            "GenTokenNameRegex": "^BlockTrain Building #[0-9]+$",
            "Holder": wallet,
            "limit": 500,
            "offset": 0
        };
            
        const { data, loading, error } = useQuery(TOKEN_QUERY,{
            variables: variables,
            // skip: wallet
        });

        if (loading) return (
            <div>
                        <p>loading....</p>
            </div>
        );;
        if (error) return (
            <div>
                        {/* <p>Connect Wallet</p> */}
            </div>
        );
        // console.log(data);
    return(
        <div>
                    
                    {data.token.length > 0 && <p>Building &#62;</p>}
                    <Row className="card_row">
                    {data.token.map((nftItem) => (
                        <Col className="card" col={6} lg={2} key={nftItem.token_id}>
                            <a  rel="noreferrer" target="_blank" href={"https://www.fxhash.xyz/gentk/"+nftItem.token_id}>
                                <img src={nftItem.display_uri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")} alt="" />
                                {nftItem.name}
                            </a>
                        </Col>
                    ))}
                   </Row>
        </div>
    );
}
export const AtmosphereSection = () => {

    
    const context = useContext(MyContext)
    const wallet = context.address


        const TOKEN_QUERY = gql`
            query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt"){
                token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
            }, limit: $limit, offset: $offset) {
                    name
                    token_id
                    display_uri
                    thumbnail_uri
                }
            }
        `;
        const variables = {
            "GenTokenNameRegex": "^BlockTrain Atmosphere #[0-9]+$",
            "Holder": wallet,
            "limit": 500,
            "offset": 0
        };
            
        const { data, loading, error } = useQuery(TOKEN_QUERY,{
            variables: variables,
            // skip: wallet
        });

        if (loading) return (
            <div>
                        <p>loading....</p>
            </div>
        );;
        if (error) return (
            <div>
                        {/* <p>Connect Wallet</p> */}
            </div>
        );
        // console.log(data);
    return(
        <div>
                    
                    {data.token.length > 0 && <p>Atmosphere &#62;</p>}
                    <Row className="card_row">
                    {data.token.map((nftItem) => (
                        <Col className="card" col={6} lg={2} key={nftItem.token_id}>
                            <a  rel="noreferrer" target="_blank" href={"https://www.fxhash.xyz/gentk/"+nftItem.token_id}>
                                <img src={nftItem.display_uri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")} alt="" />
                                {nftItem.name}
                            </a>
                        </Col>
                    ))}
                   </Row>
        </div>
    );
}
export const ObjectSection = () => {

    
    const context = useContext(MyContext)
    const wallet = context.address


        const TOKEN_QUERY = gql`
            query ($GenTokenNameRegex: String, $Holder: String, $limit: Int, $offset: Int) @api(name: "objkt"){
                token(where: {holders: {holder_address: {_eq: $Holder}, quantity: {_eq: "1"}}, name: {_regex: $GenTokenNameRegex}
            }, limit: $limit, offset: $offset) {
                    name
                    token_id
                    display_uri
                    thumbnail_uri
                }
            }
        `;
        const variables = {
            "GenTokenNameRegex": "^BlockTrain Object #[0-9]+$",
            "Holder": wallet,
            "limit": 500,
            "offset": 0
        };
            
        const { data, loading, error } = useQuery(TOKEN_QUERY,{
            variables: variables,
            // skip: wallet
        });

        if (loading) return (
            <div>
                        <p>loading....</p>
            </div>
        );;
        if (error) return (
            <div>
                        {/* <p>Connect Wallet</p> */}
            </div>
        );
        // console.log(data);
    return(
        <div>
                    
                    {data.token.length > 0 && <p>Object &#62;</p>}
                    <Row className="card_row">
                    {data.token.map((nftItem) => (
                        <Col className="card" col={6} lg={2} key={nftItem.token_id}>
                            <a  rel="noreferrer" target="_blank" href={"https://www.fxhash.xyz/gentk/"+nftItem.token_id}>
                                <img src={nftItem.display_uri.replace("ipfs://", "https://gateway.fxhash2.xyz/ipfs/")} alt="" />
                                {nftItem.name}
                            </a>
                        </Col>
                    ))}
                   </Row>
        </div>
    );
}
export const BlockTrainSection = () => {

    var ls = require('local-storage')
    const context = useContext(MyContext)
    const auth = ls.get('auth')
    const [trainList,setTrainList] = useState([]);
    // const trainTokenApi = "https://api.tzkt.io/v1/tokens/balances?account="+auth+"&token.contract=KT1AGCjqBPpdyenBdvi3YxmXWpKYp9JMuKAu&limit=10000&offset=0&balance.ne=0"
    const trainTokenApi = "https://api.akaswap.com/v2/accounts/"+context.acc?.address+"/fa2tokens?contracts=KT1AGCjqBPpdyenBdvi3YxmXWpKYp9JMuKAu&offset=0&limit=30"
    
    
    
    useEffect(() => {
        axios.get(trainTokenApi)
        .then((response) => {
            // console.log(response);
            setTrainList(response.data.tokens)
            // console.log(response);
        });
    },[trainTokenApi])
   
    // console.log(trainList);
    return(
        <div>
                    
                    {context.acc?.address && 
                    <div>
                    {trainList.length !== 0 &&<p>BlockTrain &#62;</p>}
                    <Row className="card_row">
                    {trainList && trainList.map((nftItem) => (
                        <Col className="card" col={6} lg={4} key={nftItem.tokenId}>
                         
                         <a  rel="noreferrer" target="_blank" href={"https://akaswap.com/blocktrain/"+nftItem.tokenId}>
                                    <img src={nftItem.thumbnailUri.replace("ipfs://", "https://assets.akaswap.com/ipfs/")} alt="" />
                                    {nftItem.name}
                        </a>
                        

                        </Col>
                    ))}
                   </Row>
                   </div>
                   }
        </div>
    );
}

export const PixelBoardSection = () => {

    var ls = require('local-storage')
    const auth = ls.get('auth')
    const PixelBoardApi = "https://api.akaswap.com/v2/curations/BlockTrainPixelBoard"
    const [blockTrainPixelBoardList,setBlockTrainPixelBoardList] = useState([]);
    const context = useContext(MyContext)
    

    useEffect(() => {
        const PixelBoardTokenIdData = []
        const PixelBoardTokenListData = []
        axios.get(PixelBoardApi)
        .then((response) => {
            const data = response.data[0]
            

            data.forEach((item, i) =>{
                PixelBoardTokenIdData.push(item.tokenId) 
            });
           const tokenIdToString = PixelBoardTokenIdData.join()
           const getAccountToken = "https://api.tzkt.io/v1/tokens/balances?account="+context.acc?.address+"&token.contract=KT1AFq5XorPduoYyWxs5gEyrFK6fVjJVbtCj&token.tokenId.in="+tokenIdToString+"&offset=0&limit=10000&balance.ne=0"

            axios.get(getAccountToken)
            .then((response) => {
                    const AccountTokenData = response.data
                    // console.log(AccountTokenData);
                    AccountTokenData.forEach((item, i) =>{
                        PixelBoardTokenListData.push({
                             "tokenId": item.token.tokenId,
                             "name": item.token.metadata.name.replace("BlockTrain-PixelBoard", "").replace("-", "").replace("：", ""),
                             "image": item.token.metadata.displayUri.replace("ipfs://", "https://assets.akaswap.com/ipfs/"),
                         }) 
                    });
                    setBlockTrainPixelBoardList(PixelBoardTokenListData)
                });
            });
            
      },[PixelBoardApi,auth])

      console.log(blockTrainPixelBoardList);
    return(
        <div>
                    {context.acc?.address && 
                    <div>
                    {blockTrainPixelBoardList.length !== 0 && <p>PixelBoard &#62;</p>}
                    <Row className="card_row">
                    {blockTrainPixelBoardList && blockTrainPixelBoardList.map((nftItem) => (
                        <Col className="card" col={6} lg={2} key={nftItem.tokenId}>
                         
                         <a  rel="noreferrer" target="_blank" href={"https://akaswap.com/akaobj/"+nftItem.tokenId}>
                                    <img src={nftItem.image.replace("ipfs://", "https://assets.akaswap.com/ipfs/")} alt="" />
                                    {nftItem.name}
                        </a>
                        

                        </Col>
                    ))}
                   </Row>
                   </div>}
        </div>
    );
}